import { useContext, useEffect, useState } from "react";
import * as yup from "yup";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { useFormik } from "formik";
import { Context } from "App";
import { StoreData } from "types/publisher";
import CheckoutMockImage from "assets/images/checkout_mock.png";
import { AppchargeCheckout } from "appcharge-checkout-reactjs-sdk";
import { Eye } from "iconsax-react";

// ==============================|| ACCOUNT PROFILE - SETTINGS ||============================== //

const TabCheckoutTheme = () => {
  const [checked, setChecked] = useState([
    "en",
    "email-1",
    "email-3",
    "order-1",
    "order-3",
  ]);

  const { globalPublisherData, globalExternalCheckout } =
    useContext<any>(Context);
  const [publisherData] = globalPublisherData;
  const [externalCheckout, setExternalCheckout] = globalExternalCheckout;
  const [loadingOpeningCheckout, setLoadingOpeningCheckout] =
    useState<boolean>(false);

  const validationSchema = yup.object({
    companyName: yup.string().required("Company Name is required"),
    adminName: yup.string().required("Admin Name is required"),
    domain: yup.string().required("Domain is required"),
    adminUserMail: yup.string().email().required("Admin User Mail is required"),
    logRocketId: yup.string().required("LogRocket ID is required"),
    gameLayout: yup.string(),
    storeName: yup.string().required("Store Name is required"),
    awardMail: yup.string().email().required("Award Mail is required"),
    supportImageUrl: yup.string().url(),
    checkoutConversionToLocalPrice: yup.boolean(),
    publisherSolutionModel: yup
      .string()
      .required("Publisher Solution Model is required"),
  });

  const formik = useFormik({
    initialValues: {
      primaryColor: (publisherData as StoreData)?.storeTheme?.general
        ?.buttonColor.colorOne,
      secondaryColor: (publisherData as StoreData)?.storeTheme?.general
        ?.buttonTextColor,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      // function getRandomCompanyName() {
      //   const companies = [
      //     "Acme",
      //     "Tech",
      //     "Global",
      //     "Infinite",
      //     "Alpha",
      //     "Omega",
      //     "Pinnacle",
      //     "Zenith",
      //     "Apex",
      //     "Prime",
      //     "Epic",
      //     "Future",
      //     "Spectrum",
      //     "NexGen",
      //     "Vertex",
      //     "Horizon",
      //     "Strategic",
      //     "Dynamic",
      //     "Summit",
      //     "Synergy",
      //     "Vanguard",
      //     "Frontier",
      //     "Evolve",
      //     "Aurora",
      //     "Visionary",
      //     "Phoenix",
      //     "Momentum",
      //     "Genesis",
      //     "Revolution",
      //     "Interstellar",
      //     "Quantum",
      //     "Hyperion",
      //     "Nova",
      //     "Titan",
      //     "Cascade",
      //     "Fusion",
      //     "Eclipse",
      //     "Expanse",
      //     "Velocity",
      //     "Cosmos",
      //     "Elysium",
      //     "Ascend",
      //     "Apogee",
      //     "Pioneer",
      //     "Metropolis",
      //     "Evolution",
      //     "Astral",
      //     "Frontline",
      //     "Prometheus",
      //     "Echelon",
      //     "Nebula",
      //     "Centauri",
      //     "Supreme",
      //     "Paragon",
      //     "Orion",
      //     "Advanced",
      //     "Meridian",
      //     "Pantheon",
      //     "Elemental",
      //     "Solaris",
      //     "Voyager",
      //     "Infinity",
      //     "Nexus",
      //     "Matrix",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Alpha",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Eagle",
      //     "Galaxy",
      //     "Aurora",
      //     "Solaris",
      //     "Venture",
      //     "Olympus",
      //     "Athena",
      //     "Alpha",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Eagle",
      //     "Galaxy",
      //     "Aurora",
      //     "Solaris",
      //     "Venture",
      //   ];
      //   // Randomly select two strings
      //   const firstPart =
      //     companies[Math.floor(Math.random() * companies.length)];
      //   const secondPart =
      //     companies[Math.floor(Math.random() * companies.length)];
      //   // Concatenate the two strings with a space in between
      //   return `${firstPart} ${secondPart}`;
      // }
      // function getRandomAdminName() {
      //   const firstNames = ["John", "Emily", "Michael", "Sarah"];
      //   const lastNames = ["Doe", "Smith", "Johnson", "Brown"];
      //   return `${
      //     firstNames[Math.floor(Math.random() * firstNames.length)]
      //   } ${Math.floor(Math.random() * 1000)} ${
      //     lastNames[Math.floor(Math.random() * lastNames.length)]
      //   }`;
      // }
      // function getRandomDomain() {
      //   return `test${Math.floor(Math.random() * 100)}${Math.floor(
      //     Math.random() * 100
      //   )}.appcharge.com`;
      // }
      // function getRandomEmail(adminName: any) {
      //   const domains = ["appcharge.com"];
      //   const randomDomain =
      //     domains[Math.floor(Math.random() * domains.length)];
      //   return `${adminName.toLowerCase().replace(/\s+/g, "")}@${randomDomain}`;
      // }
      // function getRandomLogRocketId() {
      //   return Math.floor(Math.random() * 1000000000).toString();
      // }
      // function getRandomGameLayout() {
      //   const layouts = ["none", "sidebar", "fullscreen", "compact"];
      //   return layouts[Math.floor(Math.random() * layouts.length)];
      // }
      // function getRandomStoreName(companyName: string) {
      //   return `${companyName} Store`;
      // }
      // function getRandomImageUrl() {
      //   return "https://example.com/support-image.jpg"; // You may replace this with an API to fetch random images
      // }
      // function getRandomBoolean() {
      //   return Math.random() < 0.5;
      // }
      // function generateRandomData() {
      //   const companyName = getRandomCompanyName();
      //   const adminName = getRandomAdminName();
      //   const domain = getRandomDomain();
      //   const adminUserMail = getRandomEmail(adminName);
      //   const logRocketId = getRandomLogRocketId();
      //   const gameLayout = getRandomGameLayout();
      //   const storeName = getRandomStoreName(companyName);
      //   const awardMail = getRandomEmail("awards");
      //   const supportImageUrl = getRandomImageUrl();
      //   const checkoutConversionToLocalPrice = getRandomBoolean();
      //   const publisherSolutionModel = "webStore"; // Assuming this is constant
      //   setCompanyData({
      //     companyName,
      //     adminName,
      //     domain,
      //     adminUserMail,
      //     logRocketId,
      //     gameLayout,
      //     storeName,
      //     awardMail,
      //     supportImageUrl,
      //     checkoutConversionToLocalPrice: false,
      //     publisherSolutionModel: "webStore",
      //   });
      // }
      // generateRandomData();
      // handleNext();
    },
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const checkoutHandler = () => {
    setLoadingOpeningCheckout(true);
    fetch(
      "https://rryhv338ih.execute-api.us-east-1.amazonaws.com/dev/createSession",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-publisher-token":
            "c2b59d063050beccd58991eb36be6d9d6953dd0704af68c3bc74cd5d12084eef",
        },
        body: '{"customer":{"id":"John Doe","email":"test@appcharge.com"},"priceDetails":{"price":1550,"currency":"usd"},"offer":{"name":"Crazy Pack","sku":"CrazyPack","assetUrl":"https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.png","description":"Crazy Pack Bundle"},"items":[{"name":"Coins","assetUrl":"https://media-dev.appcharge.com/media/product-3.png","sku":"coins_xoxoxo","quantity":6580},{"name":"Tokens","assetUrl":"https://media-dev.appcharge.com/media/bills.png","sku":"coins_xoxoxo","quantity":150},{"name":"Boosters","assetUrl":"https://media-dev.appcharge.com/media/booster.png","sku":"coins_xoxoxo","quantity":3}],"sessionMetadata":{}}',
      }
    ).then((response) => {
      response.json().then((data) => {
        setExternalCheckout({
          url: data.url,
          sessionToken: data.checkoutSessionToken,
          open: true,
        });
        setLoadingOpeningCheckout(false);
      });
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sm={12} mb={3}>
            <MainCard
              title={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">Invoice and Receipts</Typography>
                </Stack>
              }
            >
              <Stack gap={3} direction="column" width="100%">
                <Stack gap={1}>
                  <InputLabel id="companyId-label">Invoice Merchant</InputLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue=""
                    name="primaryColor"
                    value={formik.values.primaryColor}
                    onBlur={formik.handleBlur}
                    type="text"
                    error={
                      formik.touched.primaryColor &&
                      Boolean(formik.errors.primaryColor)
                    }
                    helperText={
                      formik.touched.primaryColor && formik.errors.primaryColor
                    }
                  />
                </Stack>

                <Stack gap={1}>
                  <InputLabel id="companyId-label">Company</InputLabel>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    defaultValue=""
                    type="text"
                    name="secondaryColor"
                    value={formik.values.secondaryColor}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.secondaryColor &&
                      Boolean(formik.errors.secondaryColor)
                    }
                    helperText={
                      formik.touched.secondaryColor &&
                      formik.errors.secondaryColor
                    }
                  />
                </Stack>

                {/* upload input */}
                <Stack gap={2} direction="row">
                  <Stack direction="row" spacing={2}>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="upload-background-image"
                      multiple
                      type="file"
                    />
                    <label htmlFor="upload-background-image">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          textTransform: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Upload Image
                      </Button>
                    </label>
                    <Typography variant="subtitle1">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>checkout_background.jpg</span>
                        <Button variant="text" sx={{ color: "error.main" }}>
                          Remove
                        </Button>
                      </Box>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item md={6} xs={12} sm={12} mb={3}>
            <MainCard
              title={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">Checkout Theme</Typography>
                  <Tooltip
                    title={
                      <span>
                        This opens a real checkout, you'll see the changes once
                        applied.
                      </span>
                    }
                    placement="top-start"
                    arrow
                  >
                    <Button
                      variant="contained"
                      onClick={checkoutHandler}
                      type="button"
                      disabled={loadingOpeningCheckout || externalCheckout.open}
                    >
                      <Stack
                        direction="row"
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Eye size="32" />
                        {loadingOpeningCheckout || externalCheckout.open
                          ? "Loading..."
                          : "View Checkout"}
                      </Stack>
                    </Button>
                  </Tooltip>
                </Stack>
              }
            >
              <Stack
                spacing={2.5}
                direction="column"
                width="100%"
                alignItems="center"
              >
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Text Color"
                  variant="outlined"
                  defaultValue=""
                  name="primaryColor"
                  value={formik.values.primaryColor}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setTimeout(() => {
                      formik.setFieldValue("primaryColor", newValue);
                    }, 10);
                  }}
                  onBlur={formik.handleBlur}
                  type="color"
                  error={
                    formik.touched.primaryColor &&
                    Boolean(formik.errors.primaryColor)
                  }
                  helperText={
                    formik.touched.primaryColor && formik.errors.primaryColor
                  }
                />

                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Button Color"
                  variant="outlined"
                  defaultValue=""
                  type="color"
                  name="secondaryColor"
                  value={formik.values.secondaryColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.secondaryColor &&
                    Boolean(formik.errors.secondaryColor)
                  }
                  helperText={
                    formik.touched.secondaryColor &&
                    formik.errors.secondaryColor
                  }
                />

                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Button Text Color"
                  variant="outlined"
                  defaultValue=""
                  type="color"
                  name="secondaryColor"
                  value={formik.values.secondaryColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.secondaryColor &&
                    Boolean(formik.errors.secondaryColor)
                  }
                  helperText={
                    formik.touched.secondaryColor &&
                    formik.errors.secondaryColor
                  }
                />
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="contained" disabled>
            Update Theme
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TabCheckoutTheme;
