import { useMutation, useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface SubMid {
  _id: string;
  name: string;
  default: boolean;
  enabled: boolean;
}

export interface Publisher {
  _id?: string;
  companyName?: string;
  companyId?: string;
  active?: boolean;
}

export interface PaymentConfig {
  publisherId: string;
  prioritizedSubMid: string;
  countryCode: string;
  paymentMethodName: string;
  publisher: Publisher;
  id: string;
}

export interface RoutingRulePayload
  extends Pick<
    PaymentConfig,
    "countryCode" | "paymentMethodName" | "publisherId" | "prioritizedSubMid"
  > {}

export interface PaymentProvider {
  id: number;
  name: string;
  default: boolean;
  enabled: boolean;
}

const fetchRoutingRules = async (paymentMethod = "card") => {
  return await axiosServices.get<PaymentConfig[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payment-methods/${paymentMethod}/priority-routing`
  );
};

const fetchPaymentProviders = async () => {
  return await axiosServices.get<PaymentProvider[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payments-provider`
  );
};

const addRoutingRule = async (rules: RoutingRulePayload[]) => {
  return await axiosServices.post(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payment-methods/priority-routing`,
    { rules }
  );
};

const deleteRoutingRules = async (routingPriorityIds: string[]) => {
  return await axiosServices.delete(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payment-methods/priority-routing`,
    { data: { routingPriorityIds } }
  );
};

export default function useRoutingRules(paymentMethod: string) {
  const getRoutingRules = useQuery({
    queryKey: [EQuery.GET_ROUTING_RULES, paymentMethod],
    queryFn: () => fetchRoutingRules(paymentMethod),
  });

  const getPaymentProviders = useQuery({
    queryKey: [EQuery.GET_PAYMENT_PROVIDERS],
    queryFn: () => fetchPaymentProviders(),
  });

  const updateRoutingRulesMutation = useMutation({
    mutationFn: (rules: PaymentConfig[]) =>
      axiosServices.put(
        `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payment-methods/priority-routing/update`,
        rules
      ),
  });

  const addRoutingRuleMutation = useMutation({
    mutationFn: (rules: RoutingRulePayload[]) => addRoutingRule(rules),
  });

  const deleteRoutingRulesMutation = useMutation({
    mutationFn: (routingPriorityIds: string[]) =>
      deleteRoutingRules(routingPriorityIds),
  });

  return {
    getRoutingRules,
    getPaymentProviders,
    deleteRoutingRulesMutation,
    addRoutingRuleMutation,
    updateRoutingRulesMutation,
  };
}
