import { useContext } from "react";
import { EPermissionAction, EPermissionSection, Role } from "./types";
import { Context } from "App";

export const usePermissions = () => {
  const { globalPermissions } = useContext<any>(Context);
  const [permissions] = globalPermissions;

  const hasPermission = (
    permissionSection: EPermissionSection,
    permissionAction?: EPermissionAction[] | EPermissionAction
  ) => {
    if (!permissions) return false;

    if (typeof permissionAction === "string") {
      permissionAction = [permissionAction as EPermissionAction];
    }

    const parsedPermissions =
      typeof permissions === "string" ? JSON.parse(permissions) : permissions;

    const permission = parsedPermissions.find((p: Role) => {
      const [section, action] = p.split(":");
      return (
        section === permissionSection &&
        (!permissionAction ||
          permissionAction.includes(action as EPermissionAction))
      );
    });

    return !!permission;
  };

  return { hasPermission };
};
