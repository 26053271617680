import { useEffect, useState } from "react";

// project-imports
import Routes from "routes";
import ThemeCustomization from "themes";

import Locales from "components/Locales";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
// import Customization from 'components/Customization';
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import "./App.css";

// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import React from "react";
import { StoreData } from "types/publisher";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Stack } from "@mui/material";
import dog from "assets/images/dog.png";
import { GOOGLE_CLIENT_ID } from "config";
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

export const Context = React.createContext({});

const App = () => {
  const [currentPublisherData, setCurrentPublisherData] =
    useState<StoreData | null>(null);
  const [externalCheckout, setExternalCheckout] = useState({
    url: "",
    sessionToken: "",
    open: false,
  });
  const [permissions, setPermissions] = useState<any>();
  const [showDog, setShowDog] = useState(false);

  // Create a client
  const queryClient = new QueryClient();

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      // Check if 'L' key is pressed along with Control/Command
      if (event.key === "l" && (event.ctrlKey || event.metaKey)) {
        event.preventDefault(); // Prevent the default action (e.g., bookmark shortcut in many browsers)
        // disable scrolling
        setShowDog((oldValue) => !oldValue);
      }
    });
  }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <ThemeCustomization>
          <RTLLayout>
            <Locales>
              <ScrollTop>
                <AuthProvider>
                  <>
                    {showDog && (
                      <Stack
                        position="fixed"
                        bottom={0}
                        right={100}
                        zIndex={999999}
                      >
                        <img src={dog} alt="dog" />
                      </Stack>
                    )}
                    <Notistack>
                      <Context.Provider
                        value={{
                          globalPublisherData: [
                            currentPublisherData,
                            setCurrentPublisherData,
                          ],
                          globalExternalCheckout: [
                            externalCheckout,
                            setExternalCheckout,
                          ],
                          globalPermissions: [permissions, setPermissions],
                        }}
                      >
                        <Routes />
                      </Context.Provider>
                      {/* <Customization /> */}
                      <Snackbar />
                    </Notistack>
                  </>
                </AuthProvider>
              </ScrollTop>
            </Locales>
          </RTLLayout>
        </ThemeCustomization>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
