import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link, Stack, Typography } from "@mui/material";

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: "24px 16px 0px", mt: "auto" }}
  >
    <Typography variant="caption">
      &copy; Made with ♥ by Appcharge ✨ Stardust Team
    </Typography>
    <Stack
      spacing={1.5}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* <Link
        component={RouterLink}
        to="https://ableproadmin.com"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Home
      </Link> */}
      {/* <Link
        component={RouterLink}
        to="https://phoenixcoded.gitbook.io/able-pro/v/react/"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Documentation
      </Link> */}
      <Link
        component={RouterLink}
        to="mailto:nir@appcharge.com"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Support
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
