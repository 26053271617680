import { useContext, useState } from "react";
import * as yup from "yup";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { useFormik } from "formik";

// ==============================|| ACCOUNT PROFILE - SETTINGS ||============================== //

const TabStoreTheme = () => {
  const [checked, setChecked] = useState([
    "en",
    "email-1",
    "email-3",
    "order-1",
    "order-3",
  ]);

  const validationSchema = yup.object({
    companyName: yup.string().required("Company Name is required"),
    adminName: yup.string().required("Admin Name is required"),
    domain: yup.string().required("Domain is required"),
    adminUserMail: yup.string().email().required("Admin User Mail is required"),
    logRocketId: yup.string().required("LogRocket ID is required"),
    gameLayout: yup.string(),
    storeName: yup.string().required("Store Name is required"),
    awardMail: yup.string().email().required("Award Mail is required"),
    supportImageUrl: yup.string().url(),
    checkoutConversionToLocalPrice: yup.boolean(),
    publisherSolutionModel: yup
      .string()
      .required("Publisher Solution Model is required"),
  });

  const formik = useFormik({
    initialValues: {
      general: {
        logo: "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.webp",
        logoSize: "small",
        backgroundImageMobile:
          "https://media.appcharge.com/defaults/background.png",
        backgroundImageDesktop:
          "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/360_F_267642803_KVtMHr0J2NLjWyDPidOkJVoyVCuHjoPu.jpg",
        balanceQuantityFormat: {
          billionShorthand: false,
          fractionalSeparator: ".",
          milSeparator: ",",
          millionShorthand: false,
          thousandShorthand: false,
        },
        productsQuantityFormat: {
          billionShorthand: false,
          fractionalSeparator: ".",
          milSeparator: ",",
          millionShorthand: false,
          thousandShorthand: false,
        },
        specialOfferQuantityFormat: {
          billionShorthand: true,
          fractionalSeparator: ".",
          milSeparator: ",",
          millionShorthand: true,
          thousandShorthand: true,
        },
        font: "oswald",
        buttonColor: {
          gradientDirection: "to right",
          colorOne: "#ffca59",
          colorTwo: "#c763fd",
        },
        buttonTextColor: "#00d5ff",
        favicon:
          "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/1_uAG3p-DTTjg4m2XYmqTHGw.png",
        bundlesViewModel: "twoInOne",
        bundlesInternalViewModel: "locobingo",
        specialOffersViewModel: "gallery",
        specialOffersInternalViewModel: "locobingo",
        numbersOfBundlesToDisplayDesktop: 50,
        numbersOfBundlesToDisplayMobile: 50,
        footerAppstoreLink: "",
        footerGooglePlayLink: "",
        sectionViewModel: "bagelcode",
        balancesTheme: "default",
        popupRatio: "1 / 1",
        receiptHeader:
          "https://media.appcharge.com/defaults/email_example_bg.png",
        isFooterLogoVisible: true,
      },
      login: {
        text: "Welcome to a new members-only clubs",
        font: "oswald",
        textColor: "#ffffff",
        textSize: 24,
        textWeight: "bold",
        otpButton: {
          icon: "someicon",
          text: "sometext",
        },
      },
      storeScreen: {
        bundleBorderColor: {
          gradientDirection: "to right",
          colorOne: "#fff69d",
          colorTwo: "#e4981d",
        },
        noOffersTitleText: "Lack of Special Offers",
        noOffersMessageText:
          "Sorry, but there are no any special offers for you. Please try again later.",
      },
      checkoutScreen: {
        logo: "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.webp",
        buttonColor: "#ffca59",
        buttonTextColor: "#00d5ff",
        primaryColor: "#ffca59",
        textColor: "#00d5ff",
      },
      paymentScreen: {
        headerColor: {
          colorOne: "white",
          colorTwo: "",
          gradientDirection: "",
        },
        headerText: "Payment Method",
        headerSize: 28,
        text: "How you would like to pay?",
        textColor: "#ffffff",
        textSize: 16,
        popupColor: {
          gradientDirection: "to bottom",
          colorOne: "#3b37a5",
          colorTwo: "#9892d1",
        },
        popupBorderColor: {
          gradientDirection: "to bottom",
          colorOne: "#ffc800",
          colorTwo: "#9892d1",
        },
      },
      loaderScreen: {
        headerText: "Your order is on the way!",
        headerSize: 36,
        text: "It will be ready in just a few moments",
        textColor: "white",
        textSize: 18,
        headerWeight: "bolder",
        textWeight: "bold",
        headerColor: {
          colorOne: "white",
          colorTwo: "",
          gradientDirection: "",
        },
      },
      completedScreen: {
        headerText: "Success!",
        headerSize: 36,
        text: "Relaunch your game to see your new items",
        textColor: "white",
        textSize: 18,
        backToStoreText: "Return to shop",
        headerWeight: "bold",
        textWeight: "bold",
        backToGameButtonText: "Back to game",
        headerColor: {
          colorOne: "white",
          colorTwo: "",
          gradientDirection: "",
        },
      },
    },

    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {},
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MainCard title="General">
              <Stack spacing={2.5}>
                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Background Image Desktop
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.backgroundImageDesktop}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.backgroundImageDesktop &&
                        Boolean(formik.errors.general?.backgroundImageDesktop)
                      }
                      helperText={
                        formik.touched.general?.backgroundImageDesktop &&
                        formik.errors.general?.backgroundImageDesktop
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Background Image Mobile
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.backgroundImageMobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.backgroundImageMobile &&
                        Boolean(formik.errors.general?.backgroundImageMobile)
                      }
                      helperText={
                        formik.touched.general?.backgroundImageMobile &&
                        formik.errors.general?.backgroundImageMobile
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Favicon</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.favicon}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.favicon &&
                        Boolean(formik.errors.general?.favicon)
                      }
                      helperText={
                        formik.touched.general?.favicon &&
                        formik.errors.general?.favicon
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Receipt Header
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.receiptHeader}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.receiptHeader &&
                        Boolean(formik.errors.general?.receiptHeader)
                      }
                      helperText={
                        formik.touched.general?.receiptHeader &&
                        formik.errors.general?.receiptHeader
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={<Typography color="secondary">Logo</Typography>}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.logo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.logo &&
                        Boolean(formik.errors.general?.logo)
                      }
                      helperText={
                        formik.touched.general?.logo &&
                        formik.errors.general?.logo
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Logo Size</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.logoSize}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.logoSize &&
                        Boolean(formik.errors.general?.logoSize)
                      }
                      helperText={
                        formik.touched.general?.logoSize &&
                        formik.errors.general?.logoSize
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={<Typography color="secondary">Font</Typography>}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.font}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.font &&
                        Boolean(formik.errors.general?.font)
                      }
                      helperText={
                        formik.touched.general?.font &&
                        formik.errors.general?.font
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Numbers Of Bundles To Display Desktop
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.numbersOfBundlesToDisplayDesktop
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general
                          ?.numbersOfBundlesToDisplayDesktop &&
                        Boolean(
                          formik.errors.general
                            ?.numbersOfBundlesToDisplayDesktop
                        )
                      }
                      helperText={
                        formik.touched.general
                          ?.numbersOfBundlesToDisplayDesktop &&
                        formik.errors.general?.numbersOfBundlesToDisplayDesktop
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Numbers Of Bundles To Display Mobile
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.numbersOfBundlesToDisplayMobile
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general
                          ?.numbersOfBundlesToDisplayMobile &&
                        Boolean(
                          formik.errors.general?.numbersOfBundlesToDisplayMobile
                        )
                      }
                      helperText={
                        formik.touched.general
                          ?.numbersOfBundlesToDisplayMobile &&
                        formik.errors.general?.numbersOfBundlesToDisplayMobile
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Bundles Internal View Model
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.bundlesInternalViewModel}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.bundlesInternalViewModel &&
                        Boolean(formik.errors.general?.bundlesInternalViewModel)
                      }
                      helperText={
                        formik.touched.general?.bundlesInternalViewModel &&
                        formik.errors.general?.bundlesInternalViewModel
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Bundles View Model
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.bundlesViewModel}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.bundlesViewModel &&
                        Boolean(formik.errors.general?.bundlesViewModel)
                      }
                      helperText={
                        formik.touched.general?.bundlesViewModel &&
                        formik.errors.general?.bundlesViewModel
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Special Offers Internal View Model
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.specialOffersInternalViewModel
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general
                          ?.specialOffersInternalViewModel &&
                        Boolean(
                          formik.errors.general?.specialOffersInternalViewModel
                        )
                      }
                      helperText={
                        formik.touched.general
                          ?.specialOffersInternalViewModel &&
                        formik.errors.general?.specialOffersInternalViewModel
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Special Offers View Model
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.specialOffersViewModel}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.specialOffersViewModel &&
                        Boolean(formik.errors.general?.specialOffersViewModel)
                      }
                      helperText={
                        formik.touched.general?.specialOffersViewModel &&
                        formik.errors.general?.specialOffersViewModel
                      }
                    />
                  </ListItem>
                </List>

                <Typography variant="subtitle1">Button Color</Typography>
                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Button One</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.buttonColor.colorOne}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.buttonColor?.colorOne &&
                        Boolean(formik.errors.general?.buttonColor?.colorOne)
                      }
                      helperText={
                        formik.touched.general?.buttonColor?.colorOne &&
                        formik.errors.general?.buttonColor?.colorOne
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Button Two</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.buttonColor.colorTwo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.buttonColor?.colorTwo &&
                        Boolean(formik.errors.general?.buttonColor?.colorTwo)
                      }
                      helperText={
                        formik.touched.general?.buttonColor?.colorTwo &&
                        formik.errors.general?.buttonColor?.colorTwo
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Gradient Direction
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.buttonColor.gradientDirection
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.buttonColor
                          ?.gradientDirection &&
                        Boolean(
                          formik.errors.general?.buttonColor?.gradientDirection
                        )
                      }
                      helperText={
                        formik.touched.general?.buttonColor
                          ?.gradientDirection &&
                        formik.errors.general?.buttonColor?.gradientDirection
                      }
                    />
                  </ListItem>
                </List>

                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Button Text Color
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.buttonTextColor}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.buttonTextColor &&
                        Boolean(formik.errors.general?.buttonTextColor)
                      }
                      helperText={
                        formik.touched.general?.buttonTextColor &&
                        formik.errors.general?.buttonTextColor
                      }
                    />
                  </ListItem>
                </List>

                <List
                  sx={{
                    p: 0,
                    "& .MuiListItem-root": { p: 0, py: 0.25 },
                    "& .MuiListItem-root:last-child": {
                      pb: 2.5,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Footer Appstore Link
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.footerAppstoreLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.footerAppstoreLink &&
                        Boolean(formik.errors.general?.footerAppstoreLink)
                      }
                      helperText={
                        formik.touched.general?.footerAppstoreLink &&
                        formik.errors.general?.footerAppstoreLink
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Footer GooglePlay Link
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.general.footerGooglePlayLink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.footerGooglePlayLink &&
                        Boolean(formik.errors.general?.footerGooglePlayLink)
                      }
                      helperText={
                        formik.touched.general?.footerGooglePlayLink &&
                        formik.errors.general?.footerGooglePlayLink
                      }
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Is Footer Logo Visible
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="is Footer Logo Visible"
                      value={formik.values.general?.isFooterLogoVisible}
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                </List>

                <Typography variant="subtitle1">
                  Products Quantity Format
                </Typography>
                <List
                  sx={{
                    p: 0,
                    "& .MuiListItem-root": { p: 0, py: 0.25 },
                    "& .MuiListItem-root:last-child": {
                      pb: 2.5,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Billion Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Billion Shorthand"
                      value={
                        formik.values.general?.productsQuantityFormat
                          ?.billionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Million Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Million Shorthand"
                      value={
                        formik.values.general?.productsQuantityFormat
                          ?.millionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Thousand Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Thousand Shorthand"
                      value={
                        formik.values.general?.productsQuantityFormat
                          ?.thousandShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Fractional Separator
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.productsQuantityFormat
                          .fractionalSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.productsQuantityFormat
                          ?.fractionalSeparator &&
                        Boolean(
                          formik.errors.general?.productsQuantityFormat
                            ?.fractionalSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.productsQuantityFormat
                          ?.fractionalSeparator &&
                        formik.errors.general?.productsQuantityFormat
                          ?.fractionalSeparator
                      }
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Mil Separator</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.productsQuantityFormat
                          .milSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.productsQuantityFormat
                          ?.milSeparator &&
                        Boolean(
                          formik.errors.general?.productsQuantityFormat
                            ?.milSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.productsQuantityFormat
                          ?.milSeparator &&
                        formik.errors.general?.productsQuantityFormat
                          ?.milSeparator
                      }
                    />
                  </ListItem>
                </List>

                <Typography variant="subtitle1">
                  Special Offer Quantity Format
                </Typography>
                <List
                  sx={{
                    p: 0,
                    "& .MuiListItem-root": { p: 0, py: 0.25 },
                    "& .MuiListItem-root:last-child": {
                      pb: 2.5,
                    },
                  }}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Billion Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Billion Shorthand"
                      value={
                        formik.values.general?.specialOfferQuantityFormat
                          ?.billionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Million Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Million Shorthand"
                      value={
                        formik.values.general?.specialOfferQuantityFormat
                          ?.millionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Thousand Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Thousand Shorthand"
                      value={
                        formik.values.general?.specialOfferQuantityFormat
                          ?.thousandShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Fractional Separator
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.specialOfferQuantityFormat
                          .fractionalSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.specialOfferQuantityFormat
                          ?.fractionalSeparator &&
                        Boolean(
                          formik.errors.general?.specialOfferQuantityFormat
                            ?.fractionalSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.specialOfferQuantityFormat
                          ?.fractionalSeparator &&
                        formik.errors.general?.specialOfferQuantityFormat
                          ?.fractionalSeparator
                      }
                    />
                  </ListItem>
                  <ListItem divider>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Mil Separator</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.specialOfferQuantityFormat
                          .milSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.specialOfferQuantityFormat
                          ?.milSeparator &&
                        Boolean(
                          formik.errors.general?.specialOfferQuantityFormat
                            ?.milSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.specialOfferQuantityFormat
                          ?.milSeparator &&
                        formik.errors.general?.specialOfferQuantityFormat
                          ?.milSeparator
                      }
                    />
                  </ListItem>
                </List>

                <Typography variant="subtitle1">
                  Balance Quantity Format
                </Typography>
                <List
                  sx={{
                    p: 0,
                    "& .MuiListItem-root": { p: 0, py: 0.25 },
                  }}
                >
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Billion Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Billion Shorthand"
                      value={
                        formik.values.general?.balanceQuantityFormat
                          ?.billionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Million Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Million Shorthand"
                      value={
                        formik.values.general?.balanceQuantityFormat
                          ?.millionShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Thousand Shorthand
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="Thousand Shorthand"
                      value={
                        formik.values.general?.balanceQuantityFormat
                          ?.thousandShorthand
                      }
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          Fractional Separator
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.balanceQuantityFormat
                          .fractionalSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.balanceQuantityFormat
                          ?.fractionalSeparator &&
                        Boolean(
                          formik.errors.general?.balanceQuantityFormat
                            ?.fractionalSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.balanceQuantityFormat
                          ?.fractionalSeparator &&
                        formik.errors.general?.balanceQuantityFormat
                          ?.fractionalSeparator
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Mil Separator</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={
                        formik.values.general.balanceQuantityFormat.milSeparator
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.general?.balanceQuantityFormat
                          ?.milSeparator &&
                        Boolean(
                          formik.errors.general?.balanceQuantityFormat
                            ?.milSeparator
                        )
                      }
                      helperText={
                        formik.touched.general?.balanceQuantityFormat
                          ?.milSeparator &&
                        formik.errors.general?.balanceQuantityFormat
                          ?.milSeparator
                      }
                    />
                  </ListItem>
                </List>
              </Stack>
            </MainCard>

            <Box mt={3}>
              <MainCard title="Payment Screen">
                <Stack spacing={2.5}>
                  <List
                    sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Text Size</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.textSize}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.textSize &&
                          Boolean(formik.errors.paymentScreen?.textSize)
                        }
                        helperText={
                          formik.touched.paymentScreen?.textSize &&
                          formik.errors.paymentScreen?.textSize
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Text Color</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.textColor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.textColor &&
                          Boolean(formik.errors.paymentScreen?.textColor)
                        }
                        helperText={
                          formik.touched.paymentScreen?.textColor &&
                          formik.errors.paymentScreen?.textColor
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Text</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.text}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.text &&
                          Boolean(formik.errors.paymentScreen?.text)
                        }
                        helperText={
                          formik.touched.paymentScreen?.text &&
                          formik.errors.paymentScreen?.text
                        }
                      />
                    </ListItem>
                  </List>

                  <List
                    sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Header Size</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.headerSize}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.headerSize &&
                          Boolean(formik.errors.paymentScreen?.headerSize)
                        }
                        helperText={
                          formik.touched.paymentScreen?.headerSize &&
                          formik.errors.paymentScreen?.headerSize
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Header Text</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.headerText}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.headerText &&
                          Boolean(formik.errors.paymentScreen?.headerText)
                        }
                        helperText={
                          formik.touched.paymentScreen?.headerText &&
                          formik.errors.paymentScreen?.headerText
                        }
                      />
                    </ListItem>
                  </List>

                  <Typography variant="subtitle1">Header Color</Typography>
                  <List
                    sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color One</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.headerColor?.colorOne
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.headerColor?.colorOne &&
                          Boolean(
                            formik.errors.paymentScreen?.headerColor?.colorOne
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.headerColor?.colorOne &&
                          formik.errors.paymentScreen?.headerColor?.colorOne
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color Two</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.headerColor?.colorTwo
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.headerColor?.colorTwo &&
                          Boolean(
                            formik.errors.paymentScreen?.headerColor?.colorTwo
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.headerColor?.colorTwo &&
                          formik.errors.paymentScreen?.headerColor?.colorTwo
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">
                            Gradient Direction
                          </Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.headerColor
                            ?.gradientDirection
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.headerColor
                            ?.gradientDirection &&
                          Boolean(
                            formik.errors.paymentScreen?.headerColor
                              ?.gradientDirection
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.headerColor
                            ?.gradientDirection &&
                          formik.errors.paymentScreen?.headerColor
                            ?.gradientDirection
                        }
                      />
                    </ListItem>
                  </List>

                  <Typography variant="subtitle1">
                    Popup Border Color
                  </Typography>
                  <List
                    sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color One</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.popupBorderColor?.colorOne
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.colorOne &&
                          Boolean(
                            formik.errors.paymentScreen?.popupBorderColor
                              ?.colorOne
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.colorOne &&
                          formik.errors.paymentScreen?.popupBorderColor
                            ?.colorOne
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color Two</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.popupBorderColor?.colorTwo
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.colorTwo &&
                          Boolean(
                            formik.errors.paymentScreen?.popupBorderColor
                              ?.colorTwo
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.colorTwo &&
                          formik.errors.paymentScreen?.popupBorderColor
                            ?.colorTwo
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">
                            Gradient Direction
                          </Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.popupBorderColor
                            ?.gradientDirection
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.gradientDirection &&
                          Boolean(
                            formik.errors.paymentScreen?.popupBorderColor
                              ?.gradientDirection
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupBorderColor
                            ?.gradientDirection &&
                          formik.errors.paymentScreen?.popupBorderColor
                            ?.gradientDirection
                        }
                      />
                    </ListItem>
                  </List>

                  <Typography variant="subtitle1">Popup Color</Typography>
                  <List
                    sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}
                  >
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color One</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.popupColor?.colorOne}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupColor?.colorOne &&
                          Boolean(
                            formik.errors.paymentScreen?.popupColor?.colorOne
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupColor?.colorOne &&
                          formik.errors.paymentScreen?.popupColor?.colorOne
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">Color Two</Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={formik.values.paymentScreen.popupColor?.colorTwo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupColor?.colorTwo &&
                          Boolean(
                            formik.errors.paymentScreen?.popupColor?.colorTwo
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupColor?.colorTwo &&
                          formik.errors.paymentScreen?.popupColor?.colorTwo
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-en"
                        primary={
                          <Typography color="secondary">
                            Gradient Direction
                          </Typography>
                        }
                      />
                      <TextField
                        sx={{
                          width: "50%",
                        }}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        defaultValue=""
                        name="apiKey"
                        value={
                          formik.values.paymentScreen.popupColor
                            ?.gradientDirection
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.paymentScreen?.popupColor
                            ?.gradientDirection &&
                          Boolean(
                            formik.errors.paymentScreen?.popupColor
                              ?.gradientDirection
                          )
                        }
                        helperText={
                          formik.touched.paymentScreen?.popupColor
                            ?.gradientDirection &&
                          formik.errors.paymentScreen?.popupColor
                            ?.gradientDirection
                        }
                      />
                    </ListItem>
                  </List>
                </Stack>
              </MainCard>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MainCard title="Checkout Screen">
          <Stack spacing={2.5}>
            <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={<Typography color="secondary">Logo</Typography>}
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="apiKey"
                  value={formik.values.checkoutScreen.logo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.checkoutScreen?.logo &&
                    Boolean(formik.errors.checkoutScreen?.logo)
                  }
                  helperText={
                    formik.touched.checkoutScreen?.logo &&
                    formik.errors.checkoutScreen?.logo
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={
                    <Typography color="secondary">Button Color</Typography>
                  }
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="apiKey"
                  value={formik.values.checkoutScreen.buttonColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.checkoutScreen?.buttonColor &&
                    Boolean(formik.errors.checkoutScreen?.buttonColor)
                  }
                  helperText={
                    formik.touched.checkoutScreen?.buttonColor &&
                    formik.errors.checkoutScreen?.buttonColor
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={
                    <Typography color="secondary">Button Text Color</Typography>
                  }
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="apiKey"
                  value={formik.values.checkoutScreen.buttonTextColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.checkoutScreen?.buttonTextColor &&
                    Boolean(formik.errors.checkoutScreen?.buttonTextColor)
                  }
                  helperText={
                    formik.touched.checkoutScreen?.buttonTextColor &&
                    formik.errors.checkoutScreen?.buttonTextColor
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={
                    <Typography color="secondary">Primary Color</Typography>
                  }
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="apiKey"
                  value={formik.values.checkoutScreen.primaryColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.checkoutScreen?.primaryColor &&
                    Boolean(formik.errors.checkoutScreen?.primaryColor)
                  }
                  helperText={
                    formik.touched.checkoutScreen?.primaryColor &&
                    formik.errors.checkoutScreen?.primaryColor
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={
                    <Typography color="secondary">Text Color</Typography>
                  }
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="apiKey"
                  value={formik.values.checkoutScreen.textColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.checkoutScreen?.textColor &&
                    Boolean(formik.errors.checkoutScreen?.textColor)
                  }
                  helperText={
                    formik.touched.checkoutScreen?.textColor &&
                    formik.errors.checkoutScreen?.textColor
                  }
                />
              </ListItem>
            </List>
          </Stack>
        </MainCard>

        <Box mt={3}>
          <MainCard title="Completed Screen">
            <Stack spacing={2.5}>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Back To Game Button Text
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.backToGameButtonText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.backToGameButtonText &&
                      Boolean(
                        formik.errors.completedScreen?.backToGameButtonText
                      )
                    }
                    helperText={
                      formik.touched.completedScreen?.backToGameButtonText &&
                      formik.errors.completedScreen?.backToGameButtonText
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Back To Store Text
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.backToStoreText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.backToStoreText &&
                      Boolean(formik.errors.completedScreen?.backToStoreText)
                    }
                    helperText={
                      formik.touched.completedScreen?.backToStoreText &&
                      formik.errors.completedScreen?.backToStoreText
                    }
                  />
                </ListItem>
              </List>

              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Size</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.textSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.textSize &&
                      Boolean(formik.errors.completedScreen?.textSize)
                    }
                    helperText={
                      formik.touched.completedScreen?.textSize &&
                      formik.errors.completedScreen?.textSize
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Weight</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.textWeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.textWeight &&
                      Boolean(formik.errors.completedScreen?.textWeight)
                    }
                    helperText={
                      formik.touched.completedScreen?.textWeight &&
                      formik.errors.completedScreen?.textWeight
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Color</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.textColor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.textColor &&
                      Boolean(formik.errors.completedScreen?.textColor)
                    }
                    helperText={
                      formik.touched.completedScreen?.textColor &&
                      formik.errors.completedScreen?.textColor
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Text</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.text &&
                      Boolean(formik.errors.completedScreen?.text)
                    }
                    helperText={
                      formik.touched.completedScreen?.text &&
                      formik.errors.completedScreen?.text
                    }
                  />
                </ListItem>
              </List>

              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Size</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.headerSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerSize &&
                      Boolean(formik.errors.completedScreen?.headerSize)
                    }
                    helperText={
                      formik.touched.completedScreen?.headerSize &&
                      formik.errors.completedScreen?.headerSize
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Weight</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.headerWeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerWeight &&
                      Boolean(formik.errors.completedScreen?.headerWeight)
                    }
                    helperText={
                      formik.touched.completedScreen?.headerWeight &&
                      formik.errors.completedScreen?.headerWeight
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Text</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.headerText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerText &&
                      Boolean(formik.errors.completedScreen?.headerText)
                    }
                    helperText={
                      formik.touched.completedScreen?.headerText &&
                      formik.errors.completedScreen?.headerText
                    }
                  />
                </ListItem>
              </List>

              <Typography variant="subtitle1">Header Color</Typography>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color One</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.headerColor?.colorOne}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerColor?.colorOne &&
                      Boolean(
                        formik.errors.completedScreen?.headerColor?.colorOne
                      )
                    }
                    helperText={
                      formik.touched.completedScreen?.headerColor?.colorOne &&
                      formik.errors.completedScreen?.headerColor?.colorOne
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color Two</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.completedScreen.headerColor?.colorTwo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerColor?.colorTwo &&
                      Boolean(
                        formik.errors.completedScreen?.headerColor?.colorTwo
                      )
                    }
                    helperText={
                      formik.touched.completedScreen?.headerColor?.colorTwo &&
                      formik.errors.completedScreen?.headerColor?.colorTwo
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Gradient Direction
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={
                      formik.values.completedScreen.headerColor
                        ?.gradientDirection
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.completedScreen?.headerColor
                        ?.gradientDirection &&
                      Boolean(
                        formik.errors.completedScreen?.headerColor
                          ?.gradientDirection
                      )
                    }
                    helperText={
                      formik.touched.completedScreen?.headerColor
                        ?.gradientDirection &&
                      formik.errors.completedScreen?.headerColor
                        ?.gradientDirection
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </MainCard>
        </Box>

        <Box mt={3}>
          <MainCard title="Loader Screen">
            <Stack spacing={2.5}>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Size</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.textSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.textSize &&
                      Boolean(formik.errors.loaderScreen?.textSize)
                    }
                    helperText={
                      formik.touched.loaderScreen?.textSize &&
                      formik.errors.loaderScreen?.textSize
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Weight</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.textWeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.textWeight &&
                      Boolean(formik.errors.loaderScreen?.textWeight)
                    }
                    helperText={
                      formik.touched.loaderScreen?.textWeight &&
                      formik.errors.loaderScreen?.textWeight
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Color</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.textColor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.textColor &&
                      Boolean(formik.errors.loaderScreen?.textColor)
                    }
                    helperText={
                      formik.touched.loaderScreen?.textColor &&
                      formik.errors.loaderScreen?.textColor
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Text</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.text &&
                      Boolean(formik.errors.loaderScreen?.text)
                    }
                    helperText={
                      formik.touched.loaderScreen?.text &&
                      formik.errors.loaderScreen?.text
                    }
                  />
                </ListItem>
              </List>

              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Size</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.headerSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerSize &&
                      Boolean(formik.errors.loaderScreen?.headerSize)
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerSize &&
                      formik.errors.loaderScreen?.headerSize
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Weight</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.headerWeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerWeight &&
                      Boolean(formik.errors.loaderScreen?.headerWeight)
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerWeight &&
                      formik.errors.loaderScreen?.headerWeight
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Header Text</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.headerText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerText &&
                      Boolean(formik.errors.loaderScreen?.headerText)
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerText &&
                      formik.errors.loaderScreen?.headerText
                    }
                  />
                </ListItem>
              </List>

              <Typography variant="subtitle1">Header Color</Typography>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color One</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.headerColor?.colorOne}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerColor?.colorOne &&
                      Boolean(formik.errors.loaderScreen?.headerColor?.colorOne)
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerColor?.colorOne &&
                      formik.errors.loaderScreen?.headerColor?.colorOne
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color Two</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.loaderScreen.headerColor?.colorTwo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerColor?.colorTwo &&
                      Boolean(formik.errors.loaderScreen?.headerColor?.colorTwo)
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerColor?.colorTwo &&
                      formik.errors.loaderScreen?.headerColor?.colorTwo
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Gradient Direction
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={
                      formik.values.loaderScreen.headerColor?.gradientDirection
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loaderScreen?.headerColor
                        ?.gradientDirection &&
                      Boolean(
                        formik.errors.loaderScreen?.headerColor
                          ?.gradientDirection
                      )
                    }
                    helperText={
                      formik.touched.loaderScreen?.headerColor
                        ?.gradientDirection &&
                      formik.errors.loaderScreen?.headerColor?.gradientDirection
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </MainCard>
        </Box>

        <Box mt={3}>
          <MainCard title="Login">
            <Stack spacing={2.5}>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Font</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.font}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.font &&
                      Boolean(formik.errors.login?.font)
                    }
                    helperText={
                      formik.touched.login?.font && formik.errors.login?.font
                    }
                  />
                </ListItem>
              </List>

              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Size</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.textSize}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.textSize &&
                      Boolean(formik.errors.login?.textSize)
                    }
                    helperText={
                      formik.touched.login?.textSize &&
                      formik.errors.login?.textSize
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Weight</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.textWeight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.textWeight &&
                      Boolean(formik.errors.login?.textWeight)
                    }
                    helperText={
                      formik.touched.login?.textWeight &&
                      formik.errors.login?.textWeight
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Text Color</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.textColor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.textColor &&
                      Boolean(formik.errors.login?.textColor)
                    }
                    helperText={
                      formik.touched.login?.textColor &&
                      formik.errors.login?.textColor
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Text</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.text &&
                      Boolean(formik.errors.login?.text)
                    }
                    helperText={
                      formik.touched.login?.text && formik.errors.login?.text
                    }
                  />
                </ListItem>
              </List>

              <Typography variant="subtitle1">OTP Button</Typography>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Icon</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.otpButton?.icon}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.otpButton?.icon &&
                      Boolean(formik.errors.login?.otpButton?.icon)
                    }
                    helperText={
                      formik.touched.login?.otpButton?.icon &&
                      formik.errors.login?.otpButton?.icon
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={<Typography color="secondary">Text</Typography>}
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={formik.values.login.otpButton?.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.login?.otpButton?.text &&
                      Boolean(formik.errors.login?.otpButton?.text)
                    }
                    helperText={
                      formik.touched.login?.otpButton?.text &&
                      formik.errors.login?.otpButton?.text
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </MainCard>
        </Box>

        <Box mt={3}>
          <MainCard title="Store Screen">
            <Stack spacing={2.5}>
              <Typography variant="subtitle1">Bundle Border Color</Typography>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color One</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={
                      formik.values.storeScreen.bundleBorderColor?.colorOne
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.storeScreen?.bundleBorderColor?.colorOne &&
                      Boolean(
                        formik.errors.storeScreen?.bundleBorderColor?.colorOne
                      )
                    }
                    helperText={
                      formik.touched.storeScreen?.bundleBorderColor?.colorOne &&
                      formik.errors.storeScreen?.bundleBorderColor?.colorOne
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">Color Two</Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={
                      formik.values.storeScreen.bundleBorderColor?.colorTwo
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.storeScreen?.bundleBorderColor?.colorTwo &&
                      Boolean(
                        formik.errors.storeScreen?.bundleBorderColor?.colorTwo
                      )
                    }
                    helperText={
                      formik.touched.storeScreen?.bundleBorderColor?.colorTwo &&
                      formik.errors.storeScreen?.bundleBorderColor?.colorTwo
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Gradient Direction
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="apiKey"
                    value={
                      formik.values.storeScreen.bundleBorderColor
                        ?.gradientDirection
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.storeScreen?.bundleBorderColor
                        ?.gradientDirection &&
                      Boolean(
                        formik.errors.storeScreen?.bundleBorderColor
                          ?.gradientDirection
                      )
                    }
                    helperText={
                      formik.touched.storeScreen?.bundleBorderColor
                        ?.gradientDirection &&
                      formik.errors.storeScreen?.bundleBorderColor
                        ?.gradientDirection
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </MainCard>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="contained" disabled>
            Update Store Theme
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TabStoreTheme;
