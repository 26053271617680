import React, { useMemo, useState } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Skeleton,
  Fade,
  Popover,
  Dialog,
  Grid,
  DialogTitle,
} from "@mui/material";

// third-party
import {
  useFilters,
  useRowSelect,
  useTable,
  usePagination,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { renderFilterTypes } from "utils/react-table";
import { Button } from "@mui/material";
import {
  TablePagination,
  IndeterminateCheckbox,
} from "components/third-party/ReactTable";
import RoutingRulesDrawer from "./RoutingRulesDrawer";
import useRoutingRules, { PaymentConfig } from "hooks/useRoutingRules";
import { EPaymentMethod } from "./types";
import countries from "data/countries";
import { ArrowDown2 } from "iconsax-react";
import AcConfirmDialog from "components/AcConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";

// Filter Input Component
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, Header },
}: any) => (
  <TextField
    value={filterValue || ""}
    onChange={(e) => setFilter(e.target.value || undefined)} // Set undefined to remove the filter
    placeholder={`Search ${Header}`}
    variant="outlined"
    size="small"
    fullWidth
  />
);

// Add this new component after DefaultColumnFilter
const SelectColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id, Header },
}: any) => {
  const options = useMemo(() => {
    const uniqueOptions = new Set<string>();
    preFilteredRows.forEach((row: any) => {
      uniqueOptions.add(row.values[id]);
    });
    return preFilteredRows
      .reduce((acc: any[], row: any) => {
        if (!acc.find((item) => item.country === row.values[id])) {
          acc.push({
            country: row.values[id],
            countryCode: row.original.countryCode,
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => a.country.localeCompare(b.country));
  }, [id, preFilteredRows]);

  const handleChange = (event: any) => {
    const value = event.target.value;
    // If "Select All" is selected
    if (value.includes("all")) {
      // If all options were already selected (including 'all'), clear the selection
      if (filterValue.length === options.length + 1) {
        setFilter([]);
      } else {
        // Otherwise, select all options
        setFilter(["all", ...options.map((opt: any) => opt.country)]);
      }
    } else {
      setFilter(value);
    }
  };

  return (
    <Select
      multiple
      value={filterValue || []}
      onChange={handleChange}
      size="small"
      fullWidth
      displayEmpty
      renderValue={(selected: any) => {
        const selectedArray = Array.isArray(selected) ? selected : [];
        if (selectedArray.length === 0) {
          return "All countries";
        }
        if (
          selectedArray.includes("all") ||
          selectedArray.length === options.length
        ) {
          return "All countries";
        }
        if (selectedArray.length > 1) {
          return `${selectedArray.length} countries selected`;
        }
        return (
          options.find((opt: any) => opt.country === selectedArray[0])
            ?.country || "All countries"
        );
      }}
    >
      <MenuItem value="all">
        <Stack direction="row" spacing={1} alignItems="center">
          <Checkbox
            checked={filterValue?.includes("all")}
            indeterminate={
              filterValue?.length > 0 &&
              filterValue?.length < options.length + 1
            }
          />
          <Typography>Select All</Typography>
        </Stack>
      </MenuItem>
      {options.map((option: any) => (
        <MenuItem key={option.country} value={option.country}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox checked={filterValue?.includes(option.country)} />
            <Typography>
              {
                countries.find(
                  (country) => country.threeLetterCode === option.countryCode
                )?.name
              }
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};

function ReactTable({
  columns,
  data,
  refetch,
}: {
  columns: Column[];
  data: [];
  refetch: () => void;
}) {
  const theme = useTheme();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const { deleteRoutingRulesMutation, getPaymentProviders } = useRoutingRules(
    EPaymentMethod.CARD
  );
  const [routingRulesDrawerOpen, setRoutingRulesDrawerOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedPSP, setSelectedPSP] = useState<string | null>(null);
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter, // Default filter for all columns
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        selectedRowIds: {},
        hiddenColumns: ["id"],
      },
    },
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns: Column[]) => [
        {
          id: "row-selection-chk",
          accessor: "Selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox
              indeterminate
              {...getToggleAllRowsSelectedProps()}
            />
          ),
          Cell: ({ row }: any) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <RoutingRulesDrawer
        open={routingRulesDrawerOpen}
        onClose={() => setRoutingRulesDrawerOpen(false)}
        refetch={refetch}
      />
      <Dialog
        onClose={() => setOpenUpdateDialog(false)}
        open={openUpdateDialog}
        sx={{
          "& .MuiDialog-paper": {
            width: "300px",
            maxWidth: "300px",
          },
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <DialogTitle>Change Rule(s) PSP</DialogTitle>
          </Grid>
          <Grid item xs={12} p={4} pb={6} my={-2} ml={4}>
            <Stack direction="column" spacing={2}>
              <label htmlFor="change-to-psp">Change to:</label>
              <Select
                fullWidth
                size="small"
                value={selectedPSP}
                onChange={(e) => setSelectedPSP(e.target.value)}
                sx={{
                  width: 200,
                }}
              >
                {getPaymentProviders?.data?.data?.map((psp: any) => (
                  <MenuItem value={psp.id}>{psp.name}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" pb={3} px={3} gap={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenUpdateDialog(false);
            }}
          >
            Change
          </Button>
          <Button variant="outlined" onClick={() => setOpenUpdateDialog(false)}>
            Cancel
          </Button>
        </Stack>
      </Dialog>
      <AcConfirmDialog
        handleClose={() => setDeleteModalOpen(false)}
        open={deleteModalOpen}
        title={
          <span>
            Are you sure you want to delete {Object.keys(selectedRowIds).length}{" "}
            rule(s)?
          </span>
        }
        confirmText="Delete"
        onConfirm={() => {
          const toDelete = Object.keys(selectedRowIds).map(
            (index) => (selectedFlatRows[index as any]?.original as any)?.id
          );
          deleteRoutingRulesMutation.mutate(toDelete, {
            onSuccess: () => {
              setDeleteModalOpen(false);
              refetch?.();
              enqueueSnackbar(
                `${toDelete.length} Rule(s) deleted successfully`,
                {
                  variant: "success",
                }
              );
            },
            onError: () => {
              enqueueSnackbar("Failed to delete Rule(s)", {
                variant: "error",
              });
            },
          });
        }}
      />
      <Fade in={true} timeout={1000}>
        <MainCard
          title={
            Object.keys(selectedRowIds).length ? (
              <Typography variant="caption">
                {Object.keys(selectedRowIds).length} row(s) selected
              </Typography>
            ) : (
              " "
            )
          }
          content={false}
          secondary={
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                disabled={Object.keys(selectedRowIds).length === 0}
                onClick={handleClick}
                sx={{
                  height: "48px",
                }}
              >
                Change Selected Rules
                <ArrowDown2 size={16} style={{ marginLeft: 8 }} />
              </Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  mt: 1,
                }}
              >
                <Stack sx={{ p: 2 }} spacing={2} width={212}>
                  <Stack direction="column" spacing={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        setOpenUpdateDialog(true);
                        handleClose();
                      }}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Change {Object.keys(selectedRowIds).length} Rule(s) PSP
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setDeleteModalOpen(true);
                        handleClose();
                      }}
                      sx={{
                        textTransform: "none",
                      }}
                    >
                      Delete {Object.keys(selectedRowIds).length} Rule(s)
                    </Button>
                  </Stack>
                </Stack>
              </Popover>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: "48px",
                }}
                onClick={() => {
                  setRoutingRulesDrawerOpen(true);
                }}
              >
                Add Rule
              </Button>
            </Stack>
          }
        >
          <ScrollX>
            <Stack spacing={3}>
              <Table {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow
                      {...headerGroup.getHeaderGroupProps()}
                      sx={{ "& > th:first-of-type": { width: "58px" } }}
                    >
                      {headerGroup.headers.map((column: HeaderGroup) => (
                        <TableCell
                          {...column.getHeaderProps([
                            { className: column.className },
                          ])}
                        >
                          {column.render("Header")}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: HeaderGroup) => (
                        <TableCell
                          {...column.getHeaderProps([
                            { className: column.className },
                          ])}
                        >
                          {column.canFilter && column.id !== "row-selection-chk"
                            ? column.render("Filter")
                            : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {page.map((row: Row, i: number) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        {...row.getRowProps()}
                        onClick={() => {
                          row.toggleRowSelected();
                        }}
                        sx={{
                          cursor: "pointer",
                          bgcolor: row.isSelected
                            ? alpha(theme.palette.primary.lighter, 0.35)
                            : "inherit",
                        }}
                      >
                        {row.cells.map((cell: Cell) => (
                          <TableCell
                            {...cell.getCellProps([
                              { className: cell.column.className },
                            ])}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell sx={{ p: 2, pb: 2 }} colSpan={8}>
                      <TablePagination
                        gotoPage={gotoPage}
                        rows={rows}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* <SyntaxHighlight>
              {JSON.stringify(
                {
                  selectedRowIndices: selectedRowIds,
                  "selectedFlatRows[].original": selectedFlatRows.map(
                    (d: Row) => d.original
                  ),
                },
                null,
                2
              )}
            </SyntaxHighlight> */}
            </Stack>
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  );
}

const RoutingRulesTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }: { value: string }) => (
          <Typography>{value}</Typography>
        ),
        hidden: true,
      },
      {
        Header: "Game Name",
        accessor: "gameName",
        Cell: ({ value }: { value: string }) => (
          <Typography>{value}</Typography>
        ),
      },
      {
        Header: "Country",
        accessor: "country",
        Filter: SelectColumnFilter,
        filter: (rows: Row[], columnIds: string[], filterValue: string[]) => {
          if (!filterValue?.length) return rows;
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            return filterValue.includes(rowValue);
          });
        },
        Cell: ({ value, row }: { value: string; row: any }) => {
          const country = countries.find(
            (country) =>
              country.threeLetterCode.toLowerCase() ===
              row?.original?.countryCode.toLowerCase()
          );
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                src={`https://flagcdn.com/w20/${country?.twoLetterCode.toLowerCase()}.png`}
                alt={`${value} flag`}
                style={{ width: 20, height: "auto" }}
              />
              <Typography>{country?.name}</Typography>
            </Stack>
          );
        },
      },
      {
        Header: "Payment Service Provider (Sub-MID)",
        accessor: "psp",
      },
    ],
    []
  );

  const queryClient = useQueryClient();
  const { getRoutingRules } = useRoutingRules(EPaymentMethod.CARD);
  const data = getRoutingRules?.data?.data;

  const formattedData = data?.map((item: PaymentConfig) => ({
    gameName: item.publisher?.companyName,
    country: item.countryCode,
    countryCode: item.countryCode,
    psp: (item.prioritizedSubMid as any).name,
    id: item.id,
  })) as any[];

  return getRoutingRules?.isLoading ? (
    <MainCard
      title=" "
      content={false}
      secondary={
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Skeleton variant="rounded" width={250} height={48} />
          <Skeleton variant="rounded" width={150} height={48} />
        </Stack>
      }
    >
      <ScrollX>
        <Stack spacing={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={58}>
                  <Skeleton variant="rounded" height={24} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={24} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={24} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={24} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={58}></TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={40} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={40} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" height={40} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="rounded" height={24} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" height={24} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" height={24} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="rounded" height={24} />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={8}>
                  <Skeleton variant="rounded" height={52} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </ScrollX>
    </MainCard>
  ) : (
    <ReactTable
      columns={columns}
      data={(formattedData as any) || []}
      refetch={() => {
        queryClient.invalidateQueries({
          queryKey: [EQuery.GET_ROUTING_RULES],
        });
      }}
    />
  );
};

export default RoutingRulesTable;
