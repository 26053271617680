// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Stack, Typography } from "@mui/material";

// project-imports
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import EcommerceDataChart from "sections/widget/chart/EcommerceDataChart";

import RepeatCustomerRate from "sections/widget/chart/RepeatCustomerRate";
import ProjectOverview from "sections/widget/chart/ProjectOverview";
import ProjectRelease from "sections/dashboard/default/ProjectRelease";
import AssignUsers from "sections/widget/statistics/AssignUsers";

import Transactions from "sections/widget/data/Transactions";
import TotalIncome from "sections/widget/chart/TotalIncome";

// assets
import {
  ArrowDown,
  ArrowUp,
  Book,
  Calendar,
  CloudChange,
  Wallet3,
} from "iconsax-react";
import AuthSideImg from "assets/images/auth/img-auth-sideimg.png";
import WelcomeBanner from "sections/dashboard/default/WelcomeBanner";
import { Vortex } from "components/VortexBlock";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { FlipWords } from "components/flip-words";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  const theme = useTheme();

  // get state from location
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    if (state?.type === "welcome") {
      enqueueSnackbar("You logged in successfully!", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 2000,
      });
    }
  }, [state]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <div
        className="w-[calc(100%-4rem)] mx-auto rounded-3xl overflow-hidden"
        style={{
          marginTop: "4rem",
          height: "calc(90vh - 150px)",
        }}
      >
        <Stack
          sx={{
            background: `url(${AuthSideImg}) no-repeat center center`,
            backgroundSize: "cover",
            minHeight: "100%",
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <h3 className="text-white text-xl md:text-4xl font-bold text-center mb-4">
            Welcome to
          </h3>
          <h2 className="text-white text-2xl md:text-6xl font-bold text-center">
            Appcharge's Admin Dashboard
          </h2>
          <Box my={2}></Box>
          <span className="main-screen-replacing">
            Add, Change, Delete and Manage Of
            <br />
            Companies, Games, Admin Users, Settings and more...
          </span>
        </Stack>
        {/* <Vortex
          backgroundColor="transparent"
          rangeY={800}
          particleCount={500}
          baseHue={180}
          className="flex items-center flex-col justify-center px-2 md:px-10  py-4 w-full h-full"
        >
          <h3 className="text-white text-xl md:text-4xl font-bold text-center mb-4">
            Welcome to
          </h3>
          <h2 className="text-white text-2xl md:text-6xl font-bold text-center">
            Appcharges Admin Dashboard
          </h2>
          <div className="flex flex-col sm:flex-row items-center gap-4 mt-6">
            <Link to="/publishers" className="text-white">
              <button className="px-4 py-2 bg-purple-700 hover:bg-purple-800 transition duration-200 rounded-lg text-white shadow-[0px_2px_0px_0px_#FFFFFF40_inset]">
                View Games
              </button>
            </Link>
            <Link to="/publishers/new" className="text-white">
              <button className="px-4 py-2 text-white border-white border-s-white">
                Add a new Game
              </button>
            </Link>
          </div>
        </Vortex> */}
      </div>
    </Grid>
  );
};

export default DashboardDefault;
