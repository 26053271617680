import { Context } from "App";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ redirectPath = "/login", children }: any) => {
  const { globalPermissions } = useContext<any>(Context);
  const [permissions, setPermissions] = globalPermissions;

  const localStoragePermissions = localStorage.getItem("__ac_permissions");

  if (!permissions) {
    setPermissions(localStoragePermissions);
  }

  const user = localStorage.getItem("__ac_user");

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
