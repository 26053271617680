import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import MainCard from "components/MainCard";
import { JsonEditor } from "json-edit-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import useFees from "hooks/useFees";
import { enqueueSnackbar } from "notistack";
import DiffViewer from "DiffViewer";

const Fees = () => {
  const { id } = useParams();
  const { getFees, updateFeesMutation } = useFees(id as string);
  const { hasPermission } = usePermissions();
  const [updatedFees, setUpdatedFees] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [originalData, setOriginalData] = useState<any>({});

  useEffect(() => {
    if (getFees.data) {
      setOriginalData(getFees.data.data);
      setUpdatedFees(getFees.data.data);
    }
  }, [getFees.data]);

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        title={<span>Are you sure you want to change the fees?</span>}
        text={
          <DiffViewer
            oldText={JSON.stringify(originalData, null, 2)}
            newText={JSON.stringify(updatedFees, null, 2)}
          />
        }
        btnOkTxt="Proceed"
        onOk={() => {
          updateFeesMutation.mutate(updatedFees, {
            onSuccess: () => {
              setIsOpen(false);
              setOriginalData(updatedFees);
              enqueueSnackbar("Fees updated successfully", {
                variant: "success",
              });
            },
            onError: (error) => {
              enqueueSnackbar("Error updating fees", {
                variant: "error",
              });
            },
          });
        }}
        onCancel={() => setIsOpen(false)}
      />
      <MainCard content={false}>
        <Grid container spacing={3} p={2}>
          <Grid item xs={12}>
            <Stack direction="row-reverse" gap={2}>
              <Button
                variant="contained"
                className="h-[48px]"
                onClick={() => {
                  setIsOpen(true);
                }}
                disabled={
                  !hasPermission(
                    EPermissionSection.FEES,
                    EPermissionAction.EDIT
                  ) ||
                  JSON.stringify(originalData) === JSON.stringify(updatedFees)
                }
              >
                Save Fees
              </Button>
              <Tooltip
                title="This will reset the fees to the values they had when the page was loaded or when the fees were last saved (this session)."
                arrow
              >
                <Button
                  variant="outlined"
                  className="h-[48px]"
                  onClick={() => {
                    setUpdatedFees(getFees.data?.data as any);
                    enqueueSnackbar("Fees were reset", {
                      variant: "default",
                    });
                  }}
                  disabled={
                    !hasPermission(
                      EPermissionSection.FEES,
                      EPermissionAction.EDIT
                    )
                  }
                >
                  Reset Fees
                </Button>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <JsonEditor
                // restrictEdit={true}
                // restrictDelete={true}
                // restrictAdd={true}
                minWidth="100%"
                data={updatedFees}
                onEdit={(data) => {
                  setUpdatedFees(data.newData);
                }}
                onAdd={(data) => {
                  setUpdatedFees(data.newData);
                }}
                onDelete={(data) => {
                  setUpdatedFees(data.newData);
                }}
                onUpdate={(data) => {
                  setUpdatedFees(data.newData);
                }}
                enableClipboard={false}
                theme={{
                  displayName: "Default",
                  fragments: { edit: "rgb(161, 42, 127)" },
                  styles: {
                    container: {
                      backgroundColor: "#f6f6f6",
                      fontFamily: "monospace",
                    },
                    collection: {},
                    collectionInner: {},
                    collectionElement: {},
                    dropZone: {},
                    property: "#292929",
                    bracket: { color: "rgb(0, 43, 54)", fontWeight: "bold" },
                    itemCount: {
                      color: "rgba(0, 0, 0, 0.3)",
                      fontStyle: "italic",
                    },
                    string: "rgb(203, 75, 22)",
                    number: "rgb(128, 17, 255)",
                    boolean: "purple",
                    null: {
                      color: "rgb(220, 50, 47)",
                      fontVariant: "small-caps",
                      fontWeight: "bold",
                    },
                    input: ["#292929", { fontSize: "90%" }],
                    inputHighlight: "#b3d8ff",
                    error: {
                      fontSize: "0.8em",
                      color: "red",
                      fontWeight: "bold",
                    },
                    iconCollection: "rgb(0, 43, 54)",
                    iconEdit: "edit",
                    iconDelete: "rgb(203, 75, 22)",
                    iconAdd: "edit",
                    iconCopy: "rgb(38, 139, 210)",
                    iconOk: "green",
                    iconCancel: "rgb(203, 75, 22)",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default Fees;
