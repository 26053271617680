import { useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface IAuditRecord {
  resourceName: string;
  actionType: string;
  userEmail: string;
  affectedResourceId: string;
  payload: {
    userEmail: string;
    role: string;
  };
  changeTimeISO: string;
  changeTimeLabel: string;
}

const fetchAudits = async () => {
  return await axiosServices.get<{
    audits: IAuditRecord[];
    count: number;
  }>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/audits`
  );
};

export default function useAudits() {
  const getAudits = useQuery({
    queryKey: [EQuery.GET_AUDITS],
    queryFn: fetchAudits,
  });

  return { getAudits };
}
