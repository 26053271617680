import React, { useEffect, useState } from "react";
// Import Diff2Html and its CSS
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";
import { createPatch } from "diff";
import { Stack } from "@mui/material";

interface DiffViewerProps {
  oldText: string;
  newText: string;
}

const DiffViewer: React.FC<DiffViewerProps> = ({ oldText, newText }) => {
  const [diff, setDiff] = useState("");

  useEffect(() => {
    const patch = createPatch("file.txt", oldText, newText);
    setDiff(patch);
  }, [oldText, newText]);

  // Function to generate diff HTML
  const generateDiffHtml = (diffText: string) => {
    return Diff2Html.html(diffText, {
      drawFileList: false,
      matching: "none",
      outputFormat: "side-by-side",
      renderNothingWhenEmpty: true,
      // Remove or comment out the following line:
      // showFiles: false,
      rawTemplates: {
        "generic-file-path": "",
        "generic-empty-diff": "",
      },
    });
  };

  return (
    <Stack height="100%" overflow="hidden">
      <div dangerouslySetInnerHTML={{ __html: generateDiffHtml(diff) }} />
    </Stack>
  );
};

export default DiffViewer;
