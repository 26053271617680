// project-imports
// import applications from './applications';
import widget from "./widget";
import formsTables from "./forms-tables";
import superAdmin from "./super-admin";
// import chartsMap from './charts-map';
// import support from './support';
// import pages from './pages';

// types
import { NavItemType } from "types/menu";
import companies from "./companies";
import financial from "./financial";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  // items: [widget, applications, formsTables, chartsMap, pages, support]
  items: [widget, formsTables, companies, financial, superAdmin],
};

export default menuItems;
