import {
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { Cell, Row } from "react-table";
import { useState } from "react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";
import { Stack, Typography } from "@mui/material";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { formatSectionName } from "../../util";

interface TableRowWithDetailsProps {
  row: Row;
}

const TableRowWithDetails = ({ row }: TableRowWithDetailsProps) => {
  const [open, setOpen] = useState(false);

  const permissions = (row.original as any).permissions;

  return (
    <>
      <TableRow
        {...row.getRowProps()}
        onClick={() => setOpen(!open)}
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: "action.hover" },
        }}
      >
        {row.cells.map((cell: Cell, index: number) => (
          <TableCell
            {...cell.getCellProps([{ className: cell.column.className }])}
            sx={
              index === 0
                ? { width: "90%" }
                : index === row.cells.length - 1
                ? { width: "100px" }
                : undefined
            }
          >
            {cell.render("Cell")}
            {index === 0 && (
              <IconButton onClick={() => setOpen(!open)}>
                {open ? <LuChevronUp /> : <LuChevronDown />}
              </IconButton>
            )}
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell
          colSpan={7}
          sx={{ p: 0, backgroundColor: "background.default", border: "none" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack p={4}>
              <Card sx={{ boxShadow: "none" }}>
                <CardContent>
                  <Table className="min-w-full divide-y divide-gray-200 bg-white overflow-hidden rounded-lg border-2 border-gray-500">
                    <TableHead>
                      <TableRow>
                        <TableCell>Section</TableCell>
                        <TableCell>Permissions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(EPermissionSection).map((section) => {
                        const sectionPermissions = Object.values(
                          EPermissionAction
                        ).filter((action) =>
                          permissions.includes(`${section}:${action}`)
                        );

                        return sectionPermissions.length > 0 ? (
                          <TableRow key={section}>
                            <TableCell
                              sx={{
                                fontWeight: "bold",
                                width: "30%",
                                borderRight: "1px solid #e5e7eb",
                              }}
                            >
                              {formatSectionName(section)}
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" gap={2}>
                                {sectionPermissions.map((action) => (
                                  <Typography key={`${section}:${action}`}>
                                    {action.charAt(0).toUpperCase() +
                                      action.slice(1)}
                                  </Typography>
                                ))}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : null;
                      })}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowWithDetails;
