import { To } from "history";

// material-ui
import { Drawer } from "@mui/material";
import { Box, Stack, SxProps } from "@mui/system";

// project-imports
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
// import { APP_DEFAULT_PATH } from "config";
import { useState } from "react";
import AdminPanel from "components/AdminPanel/AdminPanel";

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Stack
      width={isIcon ? "100%" : "300px"}
      ml={isIcon ? 0 : -3}
      mt={isIcon ? -2 : -3}
      pt={3}
      pb={3}
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `linear-gradient(180deg, #efe6ff, transparent)`,
        backgroundSize: "cover",
        backgroundPositionY: "-5px",
        backgroundRepeat: "no-repeat",
        "& svg": {
          width: isIcon ? "75px !important" : "100%",
        },
      }}
      onDoubleClick={() => {
        setOpen(true);
      }}
    >
      <Drawer
        open={open}
        anchor="left"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box>
          <AdminPanel />
        </Box>
      </Drawer>
      {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
    </Stack>
  );
};

export default LogoSection;
