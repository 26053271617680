import { useContext, useState } from "react";
import * as yup from "yup";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { useFormik } from "formik";
import { Context } from "App";
import { StoreData } from "types/publisher";

// ==============================|| ACCOUNT PROFILE - SETTINGS ||============================== //

const TabIntegration = () => {
  const [checked, setChecked] = useState([
    "en",
    "email-1",
    "email-3",
    "order-1",
    "order-3",
  ]);

  const { globalPublisherData } = useContext<any>(Context);
  const [publisherData] = globalPublisherData;

  const validationSchema = yup.object({
    companyName: yup.string().required("Company Name is required"),
    adminName: yup.string().required("Admin Name is required"),
    domain: yup.string().required("Domain is required"),
    adminUserMail: yup.string().email().required("Admin User Mail is required"),
    logRocketId: yup.string().required("LogRocket ID is required"),
    gameLayout: yup.string(),
    storeName: yup.string().required("Store Name is required"),
    awardMail: yup.string().email().required("Award Mail is required"),
    supportImageUrl: yup.string().url(),
    checkoutConversionToLocalPrice: yup.boolean(),
    publisherSolutionModel: yup
      .string()
      .required("Publisher Solution Model is required"),
  });

  const formik = useFormik({
    initialValues: {
      apiKey: (publisherData as StoreData)?.integration.apiKey,
      apiUrlPrimaryKey: (publisherData as StoreData)?.integration
        .apiUrlPrimaryKey,
      apiUrlSecondaryKey: (publisherData as StoreData)?.integration
        .apiUrlSecondaryKey,
      eventsWebhookUrl: (publisherData as StoreData)?.integration
        .eventsWebhookUrl,
      ordersReportingApiUrl: (publisherData as StoreData)?.integration
        .ordersReportingApiUrl,
      playerInfoSyncUrl: (publisherData as StoreData)?.integration
        .playerInfoSyncUrl,
      playersAuthWebhook: (publisherData as StoreData)?.integration
        .playersAuthWebhook,
      backToGameButtonOn: (publisherData as StoreData)?.integration
        ?.backToGameButtonOn,
      backToStoreButtonOn: (publisherData as StoreData)?.integration
        ?.backToStoreButtonOn,
      iosDeeplink: (publisherData as StoreData)?.integration.deepLinks.find(
        (link: any) => link.platform === "ios"
      )?.deepLink,
      androidDeeplink: (publisherData as StoreData)?.integration.deepLinks.find(
        (link: any) => link.platform === "android"
      )?.deepLink,
      webDeeplink: (publisherData as StoreData)?.integration.deepLinks.find(
        (link: any) => link.platform === "web"
      )?.deepLink,
      publisherToken: (publisherData as StoreData)?.integration.publisherToken,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      // function getRandomCompanyName() {
      //   const companies = [
      //     "Acme",
      //     "Tech",
      //     "Global",
      //     "Infinite",
      //     "Alpha",
      //     "Omega",
      //     "Pinnacle",
      //     "Zenith",
      //     "Apex",
      //     "Prime",
      //     "Epic",
      //     "Future",
      //     "Spectrum",
      //     "NexGen",
      //     "Vertex",
      //     "Horizon",
      //     "Strategic",
      //     "Dynamic",
      //     "Summit",
      //     "Synergy",
      //     "Vanguard",
      //     "Frontier",
      //     "Evolve",
      //     "Aurora",
      //     "Visionary",
      //     "Phoenix",
      //     "Momentum",
      //     "Genesis",
      //     "Revolution",
      //     "Interstellar",
      //     "Quantum",
      //     "Hyperion",
      //     "Nova",
      //     "Titan",
      //     "Cascade",
      //     "Fusion",
      //     "Eclipse",
      //     "Expanse",
      //     "Velocity",
      //     "Cosmos",
      //     "Elysium",
      //     "Ascend",
      //     "Apogee",
      //     "Pioneer",
      //     "Metropolis",
      //     "Evolution",
      //     "Astral",
      //     "Frontline",
      //     "Prometheus",
      //     "Echelon",
      //     "Nebula",
      //     "Centauri",
      //     "Supreme",
      //     "Paragon",
      //     "Orion",
      //     "Advanced",
      //     "Meridian",
      //     "Pantheon",
      //     "Elemental",
      //     "Solaris",
      //     "Voyager",
      //     "Infinity",
      //     "Nexus",
      //     "Matrix",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Alpha",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Eagle",
      //     "Galaxy",
      //     "Aurora",
      //     "Solaris",
      //     "Venture",
      //     "Olympus",
      //     "Athena",
      //     "Alpha",
      //     "Proxima",
      //     "Olympus",
      //     "Athena",
      //     "Eagle",
      //     "Galaxy",
      //     "Aurora",
      //     "Solaris",
      //     "Venture",
      //   ];
      //   // Randomly select two strings
      //   const firstPart =
      //     companies[Math.floor(Math.random() * companies.length)];
      //   const secondPart =
      //     companies[Math.floor(Math.random() * companies.length)];
      //   // Concatenate the two strings with a space in between
      //   return `${firstPart} ${secondPart}`;
      // }
      // function getRandomAdminName() {
      //   const firstNames = ["John", "Emily", "Michael", "Sarah"];
      //   const lastNames = ["Doe", "Smith", "Johnson", "Brown"];
      //   return `${
      //     firstNames[Math.floor(Math.random() * firstNames.length)]
      //   } ${Math.floor(Math.random() * 1000)} ${
      //     lastNames[Math.floor(Math.random() * lastNames.length)]
      //   }`;
      // }
      // function getRandomDomain() {
      //   return `test${Math.floor(Math.random() * 100)}${Math.floor(
      //     Math.random() * 100
      //   )}.appcharge.com`;
      // }
      // function getRandomEmail(adminName: any) {
      //   const domains = ["appcharge.com"];
      //   const randomDomain =
      //     domains[Math.floor(Math.random() * domains.length)];
      //   return `${adminName.toLowerCase().replace(/\s+/g, "")}@${randomDomain}`;
      // }
      // function getRandomLogRocketId() {
      //   return Math.floor(Math.random() * 1000000000).toString();
      // }
      // function getRandomGameLayout() {
      //   const layouts = ["none", "sidebar", "fullscreen", "compact"];
      //   return layouts[Math.floor(Math.random() * layouts.length)];
      // }
      // function getRandomStoreName(companyName: string) {
      //   return `${companyName} Store`;
      // }
      // function getRandomImageUrl() {
      //   return "https://example.com/support-image.jpg"; // You may replace this with an API to fetch random images
      // }
      // function getRandomBoolean() {
      //   return Math.random() < 0.5;
      // }
      // function generateRandomData() {
      //   const companyName = getRandomCompanyName();
      //   const adminName = getRandomAdminName();
      //   const domain = getRandomDomain();
      //   const adminUserMail = getRandomEmail(adminName);
      //   const logRocketId = getRandomLogRocketId();
      //   const gameLayout = getRandomGameLayout();
      //   const storeName = getRandomStoreName(companyName);
      //   const awardMail = getRandomEmail("awards");
      //   const supportImageUrl = getRandomImageUrl();
      //   const checkoutConversionToLocalPrice = getRandomBoolean();
      //   const publisherSolutionModel = "webStore"; // Assuming this is constant
      //   setCompanyData({
      //     companyName,
      //     adminName,
      //     domain,
      //     adminUserMail,
      //     logRocketId,
      //     gameLayout,
      //     storeName,
      //     awardMail,
      //     supportImageUrl,
      //     checkoutConversionToLocalPrice: false,
      //     publisherSolutionModel: "webStore",
      //   });
      // }
      // generateRandomData();
      // handleNext();
    },
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MainCard title="API Settings">
              <Stack spacing={2.5}>
                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">API Key</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiKey"
                      value={formik.values.apiKey}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.apiKey && Boolean(formik.errors.apiKey)
                      }
                      helperText={formik.touched.apiKey && formik.errors.apiKey}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          API Url Primary Key
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiUrlPrimaryKey"
                      value={formik.values.apiUrlPrimaryKey}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.apiUrlPrimaryKey &&
                        Boolean(formik.errors.apiUrlPrimaryKey)
                      }
                      helperText={
                        formik.touched.apiUrlPrimaryKey &&
                        formik.errors.apiUrlPrimaryKey
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">
                          API Url Secondary Key
                        </Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      name="apiUrlSecondaryKey"
                      value={formik.values.apiUrlSecondaryKey}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.apiUrlSecondaryKey &&
                        Boolean(formik.errors.apiUrlSecondaryKey)
                      }
                      helperText={
                        formik.touched.apiUrlSecondaryKey &&
                        formik.errors.apiUrlSecondaryKey
                      }
                    />
                  </ListItem>
                </List>
              </Stack>
            </MainCard>
          </Grid>
          <Grid item xs={12}>
            <MainCard title="Buttons and Deeplinks">
              <Stack spacing={2.5}>
                <Typography variant="subtitle1">Buttons</Typography>
                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="secondary">
                          Back to Game button
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="backToGameButtonOn"
                      value={formik.values.backToGameButtonOn}
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography color="secondary">
                          Back to Store button
                        </Typography>
                      }
                    />
                    <Checkbox
                      name="backToStoreButtonOn"
                      value={formik.values.backToStoreButtonOn}
                      onChange={formik.handleChange}
                    />
                  </ListItem>
                </List>
                <Typography variant="subtitle1">Deeplinks</Typography>
                <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={<Typography color="secondary">iOS</Typography>}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      value={formik.values.iosDeeplink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.iosDeeplink &&
                        Boolean(formik.errors.iosDeeplink)
                      }
                      helperText={
                        formik.touched.iosDeeplink && formik.errors.iosDeeplink
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={
                        <Typography color="secondary">Android</Typography>
                      }
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      value={formik.values.androidDeeplink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.androidDeeplink &&
                        Boolean(formik.errors.androidDeeplink)
                      }
                      helperText={
                        formik.touched.androidDeeplink &&
                        formik.errors.androidDeeplink
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      id="switch-list-label-en"
                      primary={<Typography color="secondary">Web</Typography>}
                    />
                    <TextField
                      sx={{
                        width: "50%",
                      }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      defaultValue=""
                      value={formik.values.webDeeplink}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.webDeeplink &&
                        Boolean(formik.errors.webDeeplink)
                      }
                      helperText={
                        formik.touched.webDeeplink && formik.errors.webDeeplink
                      }
                    />
                  </ListItem>
                </List>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MainCard title="Constants">
          <Stack spacing={2.5}>
            <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
              <ListItem>
                <ListItemText
                  id="switch-list-label-en"
                  primary={
                    <Typography color="secondary">Publisher Token</Typography>
                  }
                />
                <TextField
                  sx={{
                    width: "50%",
                  }}
                  disabled
                  label=""
                  variant="outlined"
                  defaultValue=""
                  name="ordersReportingApiUrl"
                  value={formik.values.publisherToken}
                />
              </ListItem>
            </List>
          </Stack>
        </MainCard>

        <Box mt={3}>
          <MainCard title="Webhooks">
            <Stack spacing={2.5}>
              <List sx={{ p: 0, "& .MuiListItem-root": { p: 0, py: 0.25 } }}>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Events Webhook URL
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="eventsWebhookUrl"
                    value={formik.values.eventsWebhookUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.eventsWebhookUrl &&
                      Boolean(formik.errors.eventsWebhookUrl)
                    }
                    helperText={
                      formik.touched.eventsWebhookUrl &&
                      formik.errors.eventsWebhookUrl
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Orders Reporting URL
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="ordersReportingApiUrl"
                    value={formik.values.ordersReportingApiUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ordersReportingApiUrl &&
                      Boolean(formik.errors.ordersReportingApiUrl)
                    }
                    helperText={
                      formik.touched.ordersReportingApiUrl &&
                      formik.errors.ordersReportingApiUrl
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Player Info Sync URL
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="playerInfoSyncUrl"
                    value={formik.values.playerInfoSyncUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.playerInfoSyncUrl &&
                      Boolean(formik.errors.playerInfoSyncUrl)
                    }
                    helperText={
                      formik.touched.playerInfoSyncUrl &&
                      formik.errors.playerInfoSyncUrl
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Players Authentication URL
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="playersAuthWebhook"
                    value={formik.values.playersAuthWebhook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.playersAuthWebhook &&
                      Boolean(formik.errors.playersAuthWebhook)
                    }
                    helperText={
                      formik.touched.playersAuthWebhook &&
                      formik.errors.playersAuthWebhook
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    id="switch-list-label-en"
                    primary={
                      <Typography color="secondary">
                        Orders Reporting URL
                      </Typography>
                    }
                  />
                  <TextField
                    sx={{
                      width: "50%",
                    }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    defaultValue=""
                    name="ordersReportingApiUrl"
                    value={formik.values.ordersReportingApiUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ordersReportingApiUrl &&
                      Boolean(formik.errors.ordersReportingApiUrl)
                    }
                    helperText={
                      formik.touched.ordersReportingApiUrl &&
                      formik.errors.ordersReportingApiUrl
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </MainCard>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="contained">Update Integration</Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TabIntegration;
