import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface IBlockedPlayer {
  bannedBy: string;
  bannedUntil: string; // ISO date string
  publisherPlayerId: string;
  banReason: string;
}

const fetchBlockedPlayers = async (publisherId: string) => {
  return await axiosServices.get<IBlockedPlayer[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}/blockedPlayers`
  );
};

const postBlockedPlayers = async (
  publisherId: string,
  newBlockedPlayers: Partial<IBlockedPlayer>[]
) => {
  return await axiosServices.post<Partial<IBlockedPlayer>[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}/blockPlayers`,
    newBlockedPlayers
  );
};

const putUnblockPlayer = async (
  publisherId: string,
  publisherPlayerId: string
) => {
  return await axiosServices.put<Pick<IBlockedPlayer, "publisherPlayerId">>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}/unblockPlayer`,
    { publisherPlayerId }
  );
};

export default function useBlockedPlayers(publisherId?: string) {
  const queryClient = useQueryClient();

  const getBlockedPlayers = useQuery({
    queryKey: [EQuery.GET_BLOCKED_PLAYERS, publisherId],
    queryFn: () => fetchBlockedPlayers(publisherId!),
    enabled: !!publisherId,
  });

  const unblockPlayer = useMutation({
    mutationFn: (publisherPlayerId: string) =>
      putUnblockPlayer(publisherId!, publisherPlayerId),
  });

  const addBlockedPlayers = useMutation({
    mutationFn: (newBlockedPlayers: Partial<IBlockedPlayer>[]) =>
      postBlockedPlayers(publisherId!, newBlockedPlayers),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [EQuery.GET_BLOCKED_PLAYERS, publisherId],
      });
    },
  });

  return { getBlockedPlayers, addBlockedPlayers, unblockPlayer };
}
