import {
  Button,
  Drawer,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import useFinance from "hooks/useFinance";
import { DocumentUpload } from "iconsax-react";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import * as Yup from "yup";

interface EurFinancialReportDrawerProps {
  eurFinancialReportDrawerOpen: boolean;
  setEurFinancialReportDrawerOpen: (open: boolean) => void;
}

// Update the validation schema to match your actual form fields
const EurFinancialReportSchema = Yup.object().shape({
  csvFile: Yup.mixed().required("Please upload a file"),
  currency: Yup.string().required("Currency is required"),
});

interface FormValues {
  csvFile: File | null;
  currency: string;
}

const EurFinancialReportDrawer = ({
  eurFinancialReportDrawerOpen,
  setEurFinancialReportDrawerOpen,
}: EurFinancialReportDrawerProps) => {
  const { generateEurFinancialReport } = useFinance();
  const { enqueueSnackbar } = useSnackbar();
  const fileRef = useRef<HTMLInputElement>(null);

  const formik = useFormik<FormValues>({
    initialValues: {
      csvFile: null,
      currency: "EUR",
    },
    validationSchema: EurFinancialReportSchema,
    onSubmit: (values) => {
      if (!values.csvFile) {
        enqueueSnackbar("Please upload a file", { variant: "error" });
        return;
      }

      const formData = new FormData();
      formData.append("csvFile", values.csvFile, values.csvFile.name);
      formData.append("currency", values.currency);

      generateEurFinancialReport.mutate(formData, {
        onSuccess: (data) => {
          setEurFinancialReportDrawerOpen(false);
          enqueueSnackbar("EUR Financial Report generated successfully", {
            variant: "success",
          });

          try {
            // Replace URL-safe characters and add padding if needed
            const base64Fixed = data.data.data
              .replace(/-/g, "+")
              .replace(/_/g, "/")
              .replace(/[^A-Za-z0-9+/]/g, "");

            // Add padding if needed
            const pad = base64Fixed.length % 4;
            const paddedBase64 = pad
              ? base64Fixed + "=".repeat(4 - pad)
              : base64Fixed;

            // Convert base64 to binary using browser APIs
            const binaryString = window.atob(paddedBase64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }

            // Create blob from binary data
            const blob = new Blob([bytes], { type: "application/zip" });

            // Create a temporary <a> element and trigger download
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
            link.download = `eur-financial-report-${currentDate}.zip`;
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            formik.resetForm();
          } catch (error) {
            console.error("Error processing base64:", error);
            enqueueSnackbar("Error processing the downloaded file", {
              variant: "error",
            });
          }
        },
        onError: (err) => {
          enqueueSnackbar(`Error restoring receipts: ${err.message}`, {
            variant: "error",
          });
        },
      });
    },
  });

  return (
    <Drawer
      anchor="right"
      open={eurFinancialReportDrawerOpen}
      onClose={() => setEurFinancialReportDrawerOpen(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "75%", md: "350px" },
          padding: 3,
        },
      }}
    >
      <Typography variant="h4" whiteSpace="normal" gutterBottom sx={{ mb: 2 }}>
        EUR Financial Report Generation
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack py={1} spacing={4}>
          <Stack spacing={1}>
            <InputLabel>Upload EUR Financial Report</InputLabel>
            <FormControl fullWidth>
              <input
                type="file"
                ref={fileRef}
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0];
                  if (file) {
                    formik.setFieldValue("csvFile", file);
                  }
                }}
                hidden
                accept=".csv"
              />
              <TextField
                value={formik.values.csvFile?.name || ""}
                onClick={() => fileRef.current?.click()}
                placeholder="Upload EUR Financial Report"
                error={formik.touched.csvFile && Boolean(formik.errors.csvFile)}
                helperText={formik.touched.csvFile && formik.errors.csvFile}
                InputProps={{
                  startAdornment: (
                    <Button variant="contained">
                      <DocumentUpload color="white" />
                    </Button>
                  ),
                }}
              />
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formik.isValid}
            >
              Generate
            </Button>
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default EurFinancialReportDrawer;
