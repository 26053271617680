import { useMutation } from "@tanstack/react-query";
import axiosServices from "utils/axios";

export default function useFinance() {
  const generateEurFinancialReport = useMutation({
    mutationFn: (data: FormData) => {
      return axiosServices.post(
        `https:${
          process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL
        }/admin-dashboard/payouts/split-payouts/${data?.get("currency")}`,
        data
      );
    },
  });

  return { generateEurFinancialReport };
}
