import { useContext, useState } from "react";
import * as yup from "yup";

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { useFormik } from "formik";
import { Context } from "App";
import { StoreData } from "types/publisher";
import { CloseCircle, TickCircle } from "iconsax-react";

// ==============================|| ACCOUNT PROFILE - SETTINGS ||============================== //

const TabConfiguration = () => {
  const [checked, setChecked] = useState([
    "en",
    "email-1",
    "email-3",
    "order-1",
    "order-3",
  ]);

  const { globalPublisherData } = useContext<any>(Context);
  const [publisherData] = globalPublisherData;

  const validationSchema = yup.object({
    companyName: yup.string().required("Company Name is required"),
    adminName: yup.string().required("Admin Name is required"),
    domain: yup.string().required("Domain is required"),
    adminUserMail: yup.string().email().required("Admin User Mail is required"),
    gameLayout: yup.string(),
    storeName: yup.string().required("Store Name is required"),
    awardMail: yup.string().email().required("Award Mail is required"),
    supportImageUrl: yup.string().url(),
    checkoutConversionToLocalPrice: yup.boolean(),
    publisherSolutionModel: yup
      .string()
      .required("Publisher Solution Model is required"),
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <Stack width="100%" direction="row" justifyContent="space-between">
              <ListItemAvatar>
                <TickCircle size="32" color="green" />
              </ListItemAvatar>
              <ListItemText
                primary="Company Name"
                secondary="The name of the parent company (for example: Candivore)"
              />
              <TextField
                value={(publisherData as StoreData)?.publisher?.companyName}
              />
            </Stack>
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Game Name"
              secondary="The name of the game (for example: Match Masters)"
            />
            <TextField
              value={(publisherData as StoreData)?.publisher?.companyName}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Admin name and Email (Person of contact)"
              secondary="The first invited user to the system"
            />
            <TextField
              value={(publisherData as StoreData)?.users?.[0]?.email}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Domain"
              secondary="The domain of the game (for example: matchmasters.com), relevant for CORS and boot requests"
            />
            <TextField
              value={(publisherData as StoreData)?.publisher?.domain}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Game Layout"
              secondary="The layout of the game"
            />
            <TextField
              value={
                (publisherData as StoreData)?.storeTheme?.general
                  ?.bundlesInternalViewModel
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText primary="Award Mail" secondary="" />
            <TextField
              value={(publisherData as StoreData)?.publisher?.awardMail}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Support Image URL"
              secondary="The top image that shows on support emails"
            />
            <TextField value="" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Publisher Solution Model"
              secondary="Checkout / Web store"
            />
            <TextField
              value={
                (publisherData as StoreData)?.publisher?.publisherSolutionModel
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Payment Descriptor (Invoice Merchant)"
              secondary="The name of the game that will appear in the credit card statement"
            />
            <TextField
              value={(publisherData as StoreData)?.publisher?.invoiceMerchant}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem
            alignItems="flex-start"
            sx={{
              backgroundColor: "#ffebeb",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          >
            <ListItemAvatar>
              <CloseCircle size="32" color="red" />
            </ListItemAvatar>
            <ListItemText
              primary="Receipt Descriptor"
              secondary="The name of the game that will appear on the invoice"
            />
            <TextField value="" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Receipt Header Image"
              secondary="The image that will appear on the invoice"
            />
            <TextField value="" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Abbreviation"
              secondary="Shorthand and abbreviation of the items amounts (for example: 3B, 5L, 10M)"
            />
            <TextField value="" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Support Solution Model"
              secondary="The support model of the game (Appcharge for example)"
            />
            <TextField
              value={(publisherData as StoreData)?.publisher?.supportModel}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Price Localization Model"
              secondary="Is the price forces currency to be local or not"
            />
            <TextField value="" />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <TickCircle size="32" color="green" />
            </ListItemAvatar>
            <ListItemText
              primary="Fees"
              secondary="The fees the publisher pays"
            />
            <TextField value="" />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default TabConfiguration;
