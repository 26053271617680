import { lazy } from "react";

// project-imports
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";

// render - login
const AuthLogin2 = Loadable(lazy(() => import("pages/auth/auth2/login2")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/auth1/register")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/auth1/forgot-password"))
);
const AuthCheckMail = Loadable(
  lazy(() => import("pages/auth/auth1/check-mail"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/auth/auth1/reset-password"))
);
const AuthCodeVerification = Loadable(
  lazy(() => import("pages/auth/auth1/code-verification"))
);

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <CommonLayout />,
      children: [
        {
          path: "login",
          element: <AuthLogin2 />,
        },
        {
          path: "register",
          element: <AuthRegister />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />,
        },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
        {
          path: "code-verification",
          element: <AuthCodeVerification />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
