export type Role = `${string}:${string}`;

export enum EPermissionSection {
  USERS = "admin-dashboard-users",
  COMPANIES = "companies",
  PUBLISHERS = "publishers",
  PAYOUTS = "payouts",
  PAYMENT_METHODS = "payment-methods",
  BLOCKED_PLAYERS = "blocked-players",
  FEES = "fees",
  AUDITS = "audits",
  ROLES = "admin-dashboard-roles",
  DISPUTES = "disputes",
  ADVANCED_SETTINGS = "advanced-settings",
  RESTORE_RECEIPTS = "restore-receipts",
}

export enum EPermissionAction {
  CREATE = "create",
  GET = "get",
  EDIT = "edit",
  DELETE = "delete",
}
