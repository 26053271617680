import { Box, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { Context } from "App";
import React, { useContext } from "react";

const AdminPanel = () => {
  const { globalPermissions } = useContext<any>(Context);
  const [permissions] = globalPermissions;

  return (
    <Stack p={4}>
      <Box my={2}>
        <pre>
          <em>
            <strong>User:</strong>
          </em>{" "}
          {localStorage.getItem("__ac_user")}
        </pre>
      </Box>
      <Divider />
      <Box my={2}>
        <pre>
          <em>
            <strong>Role:</strong>
          </em>{" "}
          {localStorage.getItem("__ac_role")}
        </pre>
      </Box>
      <Divider />
      <Box my={2}>
        <pre>
          <em>
            <strong>Permissions:</strong>
          </em>{" "}
          {typeof permissions === "string"
            ? JSON.stringify(JSON.parse(permissions), null, 2)
            : JSON.stringify(permissions, null, 2)}
        </pre>
      </Box>
    </Stack>
  );
};

export default AdminPanel;
