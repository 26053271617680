import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ConfirmationDialogProps {
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  btnCancelTxt?: string;
  btnOkTxt?: string;
  onOk?: () => void;
  onCancel?: () => void;
  isOpen: boolean;
}

export const ConfirmationDialog = ({
  isOpen,
  title,
  text,
  btnOkTxt,
  btnCancelTxt,
  onOk,
  onCancel,
}: ConfirmationDialogProps) => (
  <Dialog
    sx={{ "& .MuiDialog-paper": { width: "80%" } }}
    maxWidth="sm"
    open={isOpen}
    onClose={onCancel}
  >
    {title && <DialogTitle>{title}</DialogTitle>}
    {text && <DialogContent dividers>{text}</DialogContent>}

    <DialogActions>
      <Button onClick={onCancel}>{btnCancelTxt || "Cancel"}</Button>
      <Button onClick={onOk} variant="contained">
        {btnOkTxt || "Ok"}
      </Button>
    </DialogActions>
  </Dialog>
);
