import { useMutation, useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

const fetchFees = async (publisherId: string) => {
  return await axiosServices.get<{
    fees: any;
  }>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}/fees`
  );
};

const updateFees = async (publisherId: string, fees: any) => {
  return await axiosServices.put(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers/${publisherId}/fees`,
    { ...fees }
  );
};

export default function useFees(publisherId: string) {
  const getFees = useQuery({
    queryKey: [EQuery.GET_FEES, publisherId],
    queryFn: () => fetchFees(publisherId),
  });

  const updateFeesMutation = useMutation({
    mutationFn: (fees: any) => updateFees(publisherId, fees),
  });

  return { getFees, updateFeesMutation };
}
