import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const AcLoading = ({
  text,
  isFloating,
}: {
  text?: string;
  isFloating?: boolean;
}) => {
  return (
    <Stack
      sx={
        isFloating
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
            }
          : {}
      }
    >
      <Stack
        height={`calc(100vh - 270px)`}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={46} />
        <Typography variant="h5" mt={2} gutterBottom sx={{ mb: 2 }}>
          {text || "Loading"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AcLoading;
