import { Button, Dialog, DialogTitle, Grid, Stack } from "@mui/material";
import { ReactElement } from "react";

const AcConfirmDialog = ({
  handleClose,
  open,
  title,
  confirmText,
  onConfirm,
}: {
  handleClose: () => void;
  open: boolean;
  title: string | ReactElement;
  confirmText: string;
  onConfirm: () => void;
}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
      </Grid>
      <Stack direction="row" pb={3} px={3} gap={2} justifyContent="flex-end">
        <Button variant="contained" color="error" onClick={onConfirm}>
          {confirmText}
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default AcConfirmDialog;
